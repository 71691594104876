<template>
  <WStatsWidget
    v-if="isMonoPlace"
    :footerSpacer="footerSpacer"
    :linkTo="{
      name: 'Competitions',
      params: { competitorMode, periodType: 'period' },
    }"
    :loading="campaignSmartBenchmarkPreference === undefined"
    :subtitle="defaultSubtitle"
    :title="title"
    class="lrm-benchmark-widget"
    height="200px"
    contentWidth="100%"
  >
    <template #content>
      <PositionGauge
        period-type="period"
        :competitor-mode="competitorMode"
        :competitor-scope="defaultCompetitorScope"
        :lm-brand-ids="lmBrandIds"
      />
    </template>
  </WStatsWidget>
</template>

<script>
import PositionGauge from '@platform/pages/dashboard/Competitions/widgets/Position/PositionGauge'
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

export default {
  name: 'LrmBenchmarkWidget',
  components: {
    PositionGauge,
  },
  mixins: [
    WidgetMixin
  ],
  props: {
    footerSpacer: { type: Boolean },
  },
  computed: {
    ...mapGetters([
      'isMonoPlace',
      'defaultCompetitorScope',
      'networkRankingName',
      'networkRankingPlaceIds',
      'currentDashboard',
      'currentDashboardCampaignIds',
    ]),
    competitorMode() {
      if (this.isMonoPlace) {
        return 'local'
      } else {
        return 'national'
      }
    },
    title() {
      return `${this.$t(
        this.competitorMode == 'local' ?
        'lrm_benchmark_widget_title_local' :
        'lrm_benchmark_widget_title_brand'
      )} ${this.benchmarkName}`
    },
    networkTranslation() {
      return this.$store.getters.currentLexicon.translate('network', 'network') || networkRankingName
    },
    lmBrandIds() {
      return this.campaignSmartBenchmarkPreference?.lmBrandIds
    },
    benchmarkName() {
      if (this.campaignSmartBenchmarkPreference?.enabled && this.campaignSmartBenchmarkPreference?.name !== 'default') {
        return this.campaignSmartBenchmarkPreference?.name
      }

      return ''
    }
  },
  asyncComputed: {
    campaignSmartBenchmarkPreference: {
      default: undefined,
      async get() {
        const request = this.$basedRequest().select({
          campaign_smart_benchmark_preferences: [
            { 'eligible_lm_brand_ids': { as: 'lmBrandIds' } },
            { 'name': { as: 'name' } },
            { 'enabled': { as: 'enabled' } }
          ]
        }).where({
          is_default: true,
          campaign_id: this.currentDashboardCampaignIds
        })

        const campaignSmartBenchmarkPreferences = (await this.$resolve(request))?.data?.campaignSmartBenchmarkPreferences || []

        campaignSmartBenchmarkPreferences.forEach((campaignSmartBenchmarkPreference) => {
          campaignSmartBenchmarkPreference.lmBrandIds = [
            ...this.currentDashboard.lmBrandIds,
            ...campaignSmartBenchmarkPreference.lmBrandIds
          ]
        })

        return campaignSmartBenchmarkPreferences[0]
      }
    }
  }
}
</script>
