<template>
  <WStatsWidget
    class="widget-lmbrand-ranking"
    :title="$t(title)"
    :cols="12"
    :justifyCenter="false"
    :loading="loading"
    ref="widget"
    :height="fullScreenMode ? undefined : '390px'"
    contentWidth="100%"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #options>
      <w-drop-down-menu
        data-html2canvas-ignore
        v-if="!loading"
        :items="exportOptions"
        icon="mdi-download">
      </w-drop-down-menu>
    </template>

    <template #content>
      <widget-ranking-table
        :rows="rows"
        :isMonoPlace="false"
        :competitorMode="'national'"
        :displayEvolution="sqlDatesScope && sqlDatesScope.key !== 'genesis'"
        @onRowClick="onRowClick"
        :zoomable="true"
        :active="active"
        v-if="!loading"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import CompetitorModeTypeNav from '../navigation/CompetitorModeTypeNav'
  import WidgetRankingTable from './RankingTable'
  import HeaderSubtitle from '../shared/HeaderSubtitle'
  import { mapGetters } from 'vuex'
  import _debounce from "lodash/debounce"
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    name: "LmBrandRanking",
    components: {
      CompetitorModeTypeNav,
      WidgetRankingTable,
      HeaderSubtitle
    },
    props: ['lmBrandIds', 'countryCode', 'active', 'minimalNumberOfFeedbackForRanking'],
    mixins: [
      WidgetMixin,
    ],

    data() {
      return {
        title: this.$t('lm_brand_ranking_table_title'),
        activeTopicId: (isNaN(parseInt(this.$route?.query?.topicId)) ? 'undefined' : Number(this.$route?.query?.topicId)),
        dataLoading: true
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterDates',
        'currentDashboard',
        'sqlDatesScope',
        'currentAdmin',
        'fullScreenMode',
        'currentUser',
        'dashboardFilterReady',
      ]),
      loading() {
        return this.dataLoading || this.lmBrandsRanking?.length === 0
      },
      rows() {
        this.active

        const rows = this.ranked(this.lmBrandsRanking, this.nbPlacesByLmBrandId, this.minimalNumberOfFeedbackForRanking)

        if (this.comparedLmBrandsRanking && rows) {
          for (const row of rows) {
            const comparedRows = this.ranked(this.comparedLmBrandsRanking, this.nbPlacesByLmBrandId, this.minimalNumberOfFeedbackForRanking)
            const comparedLmBrandData = comparedRows.find((lmBrandData) => lmBrandData.lmBrandId === row.lmBrandId)

            row.avgScoreEvolution = this.$helpers.numeric.safeDiff(comparedLmBrandData?.avgScore, row.avgScore)
            row.nbReviewEvolution = this.$helpers.numeric.safeDiff(comparedLmBrandData?.nbReview, row.nbReview)
            row.rankEvolution = this.$helpers.numeric.safeDiff(row.rank, comparedLmBrandData?.rank)
          }
        }

        return rows
      },
      lmBrandsBaseRequest() {
        return this.$basedRequest().select({
          lm_brand_topic_summary_months: [
            { SUM_nb_review: { as: 'nbReview' } },
            { avg_score: { as: 'avgScore' } }
          ]
        }).where({
          lm_brand_id: this.lmBrandIds,
          country_code: this.countryCode,
          stem_group_topic_id: (this.activeTopicId === 'undefined' ? null : this.activeTopicId)
        }).order([
          [ 'avg_score', 'desc' ],
          [ 'SUM_nb_review', 'desc' ],
          [ 'MAX_lm_brands_name', 'desc' ]
        ]).group('lm_brand_id')
      },
      exportOptions() {
        const options = [
          { title: `${this.$t('export_widget')} (pdf)`, onClick: this.exportWidgetPdf },
          { title: `${this.$t('export_page')} (pdf)`, onClick: this.exportPagePdf }
        ]
        options.push({ title: `${this.$t('export_feedbacks')} (csv)`, onClick: this.exportReviews })

        return options
      }
    },
    asyncComputed: {
      async lmBrandsRanking() {
        if (!this.dashboardFilterReady) {
          return
        }

        this.dataLoading = true

        const request =  this.lmBrandsBaseRequest.dateBetween(
          this.sqlDatesScope.dateBegin,
          this.sqlDatesScope.dateEnd
        ).select({
           lm_brand_topic_summary_months: [
            { MAX_lm_brands_name: { as: 'name' } },
            { MAX_lm_brands_logo_thumb_url: { as: 'logoThumbUrl' } }
          ]
        })

        const lmBrandsRanking = (await this.$resolve(request))

        this.dataLoading = false
        return lmBrandsRanking?.data?.lmBrandTopicSummaryMonths || []
      },
      async comparedLmBrandsRanking() {
        if (!this.dashboardFilterReady) {
          return
        }
        if (this.sqlDatesScope.comparedDateBegin ||  this.sqlDatesScope.comparedDateEnd) {
          const request = this.lmBrandsBaseRequest.dateBetween(
            this.sqlDatesScope.comparedDateBegin,
            this.sqlDatesScope.comparedDateEnd
          )
          const lmBrandsRanking = (await this.$resolve(request))
          return lmBrandsRanking?.data?.lmBrandTopicSummaryMonths || []
        }
      },
      async nbPlacesByLmBrandId() {
        const request =  this.$basedRequest().select({
          lm_brand_places: [
            { COUNT_lm_place_id: { as: 'nbPlace' } }
          ]
        }).where({
          lm_brand_id: this.lmBrandIds
        }).where({
          lm_places_country_code: this.countryCode
        }).group('lm_brand_id')

        const nbPlacesByLmBrandId = (await this.$resolve(request))

        return nbPlacesByLmBrandId.data
      }
    },
    watch: {
      '$route.query.topicId'(newVal, oldVal) {
        if (newVal == undefined) {
          this.activeTopicId = 'undefined'
        } else if (this.activeTopicId !== newVal) {
          this.activeTopicId = Number(newVal)
        }
      }
    },
    methods: {
      onRowClick(item) {
        this.$router.push({ params: {
          competitorScope: item.lmBrandId
        }, query: { topicId: this.activeTopicId } })
      },
      onTopicChange(value) {
        this.$router.push({ query: { topicId: (value == 'undefined' ? null : value) }})
      },
      ranked(lmBrandsRanking, nbPlacesByLmBrandId, minimalNumberOfFeedbackForRanking) {
        if (lmBrandsRanking && nbPlacesByLmBrandId && minimalNumberOfFeedbackForRanking) {
          const rankedRows = []
          const notRankedRows = []
          let rank = 1

          for (const [_, lmBrandData] of Object.entries(lmBrandsRanking || {})) {

            const highlighted = this.active ?
              this.active.id === lmBrandData.lmBrandId :
              this.currentDashboard.lmBrandIds.includes(lmBrandData.lmBrandId)

            const row = {
              "rank": null,
              "highlighted": highlighted,
              "lmBrandId": lmBrandData.lmBrandId,
              "logoUrl": lmBrandData.logoThumbUrl,
              "name": lmBrandData.name,
              "nbReview": lmBrandData.nbReview,
              "avgScore": lmBrandData.avgScore,
              "nbPlaces": nbPlacesByLmBrandId[lmBrandData.lmBrandId]?.nbPlace || 0
            }

            if (lmBrandData.nbReview >= minimalNumberOfFeedbackForRanking) {
              row.rank = rank
              rank += 1
              rankedRows.push(row)
            } else {
              notRankedRows.push(row)
            }
          }

          return [...rankedRows, ...notRankedRows]
        }
      },
      exportPagePdf: _debounce(async function() {
        const oldValue = this.fullScreenMode

        try {
          await this.$store.dispatch('updateFullScreenMode', true)
          await this.$htmlToPdf(document.getElementById('platform'), `${this.currentDashboard.name}_benchmark_${this.countryCode}_page.pdf`, { windowWidth: 595.28 * 1.6 })
          this.$store.dispatch('updateFullScreenMode', oldValue)
          this.$store.dispatch("notifySuccess")
        } catch (error) {
          console.error(error)
          this.$store.dispatch('updateFullScreenMode', oldValue)
          this.$store.dispatch("notifyError")
        }
      }, 200),
      exportWidgetPdf: _debounce(async function() {
        const oldValue = this.fullScreenMode

        try {
          await this.$store.dispatch('updateFullScreenMode', true)
          await this.$htmlToPdf(this.$refs.widget.$el, `${this.currentDashboard.name}_benchmark_${this.countryCode}_widget.pdf`, { windowWidth: 595.28 * 1.6 })
          this.$store.dispatch('updateFullScreenMode', oldValue)
          this.$store.dispatch("notifySuccess")
        } catch (error) {
          console.error(error)
          this.$store.dispatch('updateFullScreenMode', oldValue)
          this.$store.dispatch("notifyError")
        }
      }, 200),
      async exportReviews() {
        try {
          let monthFrom = null
          let monthTo = (this.sqlDatesScope.dateEnd ?  this.$helpers.dates.sqlDateEndOfMonth(this.sqlDatesScope.dateEnd) : null)

          if (this.sqlDatesScope.dateBegin) {
            const day = Number(this.$helpers.dates.day(this.sqlDatesScope.dateBegin))
            monthFrom = this.$helpers.dates.sqlDateStartOfMonth(this.sqlDatesScope.dateBegin, day > 1 ? 1 : 0)
          }

          this.$store.dispatch('notifyInfo', { message: this.$t("export_will_be_sent_by_mail", { mail: (this.currentAdmin ? this.currentAdmin.email : this.currentUser.mail) }), timeout: 15000 })
          const response = await this.$api.wizville.lrm.exportLmBrandReviews(
            'gmb',
            this.lmBrandIds,
            'review',
            {
              countryCode: this.countryCode,
              dateBegin: monthFrom,
              dateEnd: monthTo
            }
          )

          if (response.csv) {
            this.$textToFile('wizville_export.csv', response.csv)
          }

          this.$store.dispatch('notifySuccess', {
            message: response.message,
            timeout: 5000,
          })
        } catch (e) {
          this.$store.dispatch('notifyError', {
            message: e.message,
            timeout: 5000,
          })
        }
      }
    }
  }
</script>
