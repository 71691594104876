<template>
  <WStatsWidget
    class="topics-verbatim-topics-widget"
    :title="title"
    :subtitle="displayedSubtitle"
    :empty="!formatedTopics || formatedTopics.length == 0"
    :loading="loadingTopics || campaign === undefined"
    height="360px"
    :cols="6"
    contentWidth="100%"
    :justifyCenter="false"
  >
    <template #title>
      <div class="d-flex">
        <a v-if="zoomedTopic" @click="zoomedTopic = null">
          <v-icon small style="position: relative; bottom: 1px; padding-right: 5px">mdi-keyboard-backspace</v-icon>
          <span v-if="zoomedTopic">{{ zoomedTopic.topicName }}</span>
        </a>
        <span v-else>{{ $t(title) }}</span>
      </div>
    </template>

    <template #content>
      <div v-show="!zoomedTopic">
        <WWeightRepartition
          class="pb-3"
          :items="formatedTopics"
          :minLabelValue="1"
          :weightEvolutionDecimals="1"
          :weightEvolutionReverse="evolutionReverse"
        >
          <template #item-link="{ item }">
            <v-icon class="c-lightgrey" small @click="zoomedTopic = item.link">mdi-eye-outline</v-icon>
          </template>
        </WWeightRepartition>
      </div>

      <div v-if="zoomedTopic">
        <v-data-table
          :loading="loadingReviews"
          :headers="reviewsHeaders"
          :items="reviews"
          fixedHeader
          dense
          height="250px"
          mobileBreakpoint="0"
          :options.sync="reviewsOptions"
          :server-items-length="zoomedTopic && zoomedTopic.topicTotal"
        >
          <template #header.score="{}">
            <v-icon v-if="reviewsOptions.sortBy[0] === 'score' && reviewsOptions.sortDesc[0]" color="success" class=" f-18">mdi-emoticon-happy-outline</v-icon>
            <v-icon v-else-if="reviewsOptions.sortBy[0] === 'score'" color="error" class="f-18">mdi-emoticon-sad-outline</v-icon>
            <v-icon v-else color="grey" class=" f-18">mdi-star</v-icon>
          </template>

          <template #item.createdAt="{ value }">
            <WDate :date="value" font="f-12" />
          </template>

          <template #item.score="{ value }">
            <v-rating
              v-if="maxScale == 5"
              class="w-rating d-none d-sm-flex"
              background-color="grey lighten-2"
              readonly
              :value="maxScale == 10 ? Math.round(value / 2) : Math.round(value * 2) / 2"
              half-increments
              length="5"
              size="20"
              :color="$colors.gold"
            ></v-rating>
            <span v-else class="fb-13" :style="scoreStyle">{{ value }}</span>
          </template>

          <template #item.verbatim="{ value }">
            <div class="f-12 lh-14 py-1">{{ value }}</div>
          </template>

          <template #item.voterUniqueId="{ value }">
            <router-link :to="{ name: 'Feedbacks', params: { status: 'all', subStatus: 'all', voterUniqueId: value, section: 'review' } }" target="_blank">
              <v-icon class="c-lightgrey" small>mdi-open-in-new</v-icon>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </template>

    <template #options>
      <WSearchInput
        v-if="zoomedTopic"
        v-model="search"
        :key="`if_${zoomedTopic}`"
        input-width="200px"
      />
      <WSearchInput
        v-else
        v-model="topicSearch"
        :key="`else_${zoomedTopic}`"
        :updateOnChange="true"
        input-width="200px"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    props: {
     positive: {
        type: Boolean,
        required: true
      },
      indicator: {
        required: false
      },
      campaign: {
        required: false
      }
    },
    name: "TopicsVerbatimTopicsWidget",
    mixins: [
      WidgetMixin
    ],
    data() {
      return {
        zoomedTopic: null,
        loadingTopics: false,
        loadingReviews: false,
        reviewsOptions: { sortDesc: [ true ] },
        page: 1,
        itemsPerPage: 10,
        sortBy: "created_at",
        sortDirection: "desc",
        reviewsHeaders: [
          {
            text: "🕐",
            align: 'start',
            value: 'createdAt'
          },
          { text: '⭐', value: 'score', align: 'start', class: "nowrap" },
          { text: '', value: 'verbatim', cellClass: "full-width", sortable: false },
          { text: '', value: 'voterUniqueId', sortable: false }
        ],
        search: "",
        topicSearch: ""
      }
    },
    computed: {
      ...mapGetters([
        'isMonoPlace',
        'hasAccessToAllDashboardPlaces',
        'dashboardFilterDatesText',
        'dashboardFilterRequest',
        'dashboardAvgScale',
        'sqlComparedDateBegin',
        'sqlComparedDateEnd',
        'mainCampaign',
        'dashboardFilterReady',
      ]),
      isIndicatorTypeTopic() {
        return this.indicator?.type === "topic"
      },
      isIndicatorTypeNps() {
        return this.indicator?.type === "nps"
      },
      isIndicatorTypeCampaignIndicator() {
        return this.indicator?.type === "campaign_indicator"
      },
      indicatorValue() {
        return this.indicator?.value
      },
      title() {
        if (this.campaign?.hasTextAnalysisEnabled) {
          return (this.positive ? this.$t('text-analysis-widget-positive-title') : this.$t('text-analysis-widget-negative-title'))
        } else {
          return (this.positive ? this.$t('verbatim-topics-widget-positive-title') : this.$t('verbatim-topics-widget-negative-title'))
        }
      },
      barColor() {
        return (this.positive ? this.$colors.success : this.$colors.error)
      },
      evolutionReverse() {
        return (this.positive ? false : true)
      },
      feeling() {
        return (this.positive ? 0 : [1, 2])
      },
      maxScale() {
        return this.campaign?.avgScoreScale?.max || this.dashboardAvgScale?.max
      },
      scoreRange() {
        if (this.positive) {
          return (this.maxScale === 5 && !this.isIndicatorTypeNps ? { min: 4, max: 5 } : { min: 9, max: 10 })
        } else {
          return (this.maxScale === 5 && !this.isIndicatorTypeNps ? { min: 0, max: 4 } : { min: 0, max: 9 })
        }
      },
      scoreStyle() {
        return {
          color: this.barColor
        }
      },
      exportItems() {
        return [
          { title: 'PNG', onClick: "#" },
          { title: 'PDF', onClick: "#'" }
        ]
      },
      scoreColumn() {
        if (this.isIndicatorTypeTopic) {
          return { voter_avg_topics_avg_topic: { as: 'score' } }
        } else if (this.isIndicatorTypeNps) {
          return { nps: { as: 'score' } }
        } else {
          return { avg_score: { as: 'score', cast: "integer" } }
        }
      },
      baseRequest() {
        let request = this.dashboardFilterRequest

        if (this.campaign?.id) {
          request = request.where({ campaign_id: this.campaign.id})
        }

        if (this.isIndicatorTypeTopic) {
          request = request.where({
            voter_avg_topics_topic_id: this.indicatorValue?.id,
            voter_avg_topics_avg_topic: { "<=>": this.scoreRange }
          })
        }

        if (this.isIndicatorTypeCampaignIndicator) {
          request = request.where({
            avg_score: { "<=>": this.scoreRange }
          })
        }

        if (this.isIndicatorTypeNps) {
          request = request.where({
            nps: { "<=>": this.scoreRange }
          })
        }

        return request
      },
      formatedTopics() {
        let formatedTopics = [...(this.topics || [])]

        if (this.topicSearch) {
          const searchReg = new RegExp(this.topicSearch.normalize("NFD")?.replace(/\p{Diacritic}/gu, ""), "i")
          formatedTopics = formatedTopics.filter((topic) => topic.name.normalize("NFD")?.replace(/\p{Diacritic}/gu, "").search(searchReg) !== -1)
        }

        if (this.topicsComparedByTopicId && this.topics) {
          formatedTopics.forEach((topic) => {
            const topicCompared = this.topicsComparedByTopicId[topic.topicId]

            if (topicCompared) {
              topic.valueEvolution = this.$helpers.numeric.safeDiff(topicCompared.value, topic.value, 0)
              topic.weightEvolution = this.$helpers.numeric.safeDiff(topicCompared.weight, topic.weight, 1)
            }
          })
        }

        return formatedTopics
      },
      hasTextAnalysisEnabled() {
        return this.campaign?.hasTextAnalysisEnabled || this.mainCampaign?.hasTextAnalysisEnabled
      }
    },
    watch: {
      reviewsOptions: {
        handler(newOptions) {
          this.page = newOptions.page
          this.itemsPerPage = newOptions.itemsPerPage
          this.sortDirection = (newOptions.sortDesc[0] === false ? 'ASC' : 'DESC')

          const sort = newOptions.sortBy[0]
          if (sort === 'score') {
            this.sortBy = Object.keys(this.scoreColumn)[0]
          } else if (sort === 'createdAt') {
            this.sortBy = 'created_at'
          } else {
            this.sortBy = 'created_at'
          }
        },
        deep: true,
      },
    },
    asyncComputed: {
      async reviews() {
        if (this.zoomedTopic) {
          this.loadingReviews = true
          let request = this.baseRequest.select({
            topics_verbatims: [
              { created_at: { as: 'created_at' } },
              { verbatims_content: { as: 'verbatim' } },
              { voters_unique_id: { as: 'voterUniqueId' } },
              this.scoreColumn
            ]
          }).where({
            [this.hasTextAnalysisEnabled ? 'text_analysis_topic_id' : 'topic_id']: this.zoomedTopic.topicId,
            feeling: this.feeling,
            verbatim_id: { is_main_verbatim: true }
          }).order([this.sortBy, this.sortDirection]).limit(
            this.itemsPerPage
          ).offset(
            (this.page - 1) * this.itemsPerPage
          )

          if (this.search) {
            request = request.where({
              verbatims_ts_content: { '@@': { search: this.search.normalize("NFD")?.replace(/\p{Diacritic}/gu, "")?.replace(/  +/g, ' ') } }
            })
          }

          let reviews = (await this.$resolve(request))?.data?.topicsVerbatims

          this.loadingReviews = false
          return reviews
        }
      },
      async topics() {
        return await this.fetchTopics(this.baseRequest)
      },
      async topicsComparedByTopicId() {
        if (!this.dashboardFilterReady) {
          return
        }

        const sqlComparedDateBegin = this.sqlComparedDateBegin
        const sqlComparedDateEnd = this.sqlComparedDateEnd
        const request = this.baseRequest

        if (sqlComparedDateBegin || sqlComparedDateEnd) {
          return (await this.fetchTopics(request, sqlComparedDateBegin, sqlComparedDateEnd)).reduce((h, topicsVerbatim) => {
            h[topicsVerbatim.topicId] = topicsVerbatim
            return h
          }, {})
        }
      }
    },
    methods: {
      async fetchTopics(request, dateBegin = null, dateEnd = null) {
        this.loadingTopics = true
        let items = []

        const nbVoter = await this.fetchNbVoter(request, dateBegin, dateEnd)

        if (nbVoter > 0) {
          const topicsVerbatims = await this.fetchTopicsVerbatims(request, dateBegin, dateEnd)

          items = topicsVerbatims.map(topicsVerbatim => {
            return {
              topicId: topicsVerbatim.topicId,
              name: topicsVerbatim.topicName,
              value: topicsVerbatim.nbTopic,
              weight: (nbVoter > 0 ? (topicsVerbatim.nbTopic * 100.0 / nbVoter) : 0),
              barColor: this.barColor,
              link: { topicId: topicsVerbatim.topicId, topicName: topicsVerbatim.topicName, topicTotal: topicsVerbatim.nbTopic }
            }
          })
        }

        this.loadingTopics = false
        return items
      },
      async fetchNbVoter(request, dateBegin = null, dateEnd = null) {
        const nbVoterRequest = (dateBegin || dateEnd ? request.dateBetween(dateBegin, dateEnd) : request)
        const req = nbVoterRequest.select({
          voters: [{ COUNT_DISTINCT_id: { as: 'nbVoter' } }]
        })

        const nbVoter = (await this.$resolve(req)).first()?.nbVoter

        return nbVoter
      },
      async fetchTopicsVerbatims(request, dateBegin = null, dateEnd = null) {
        let fetchTopicsRequest = (dateBegin || dateEnd ? request.dateBetween(dateBegin, dateEnd) : request)

        fetchTopicsRequest = this.contextTopicRequest(fetchTopicsRequest).where({
          feeling: this.feeling,
          verbatim_id: { is_main_verbatim: true },
        })

        const topicsVerbatims = (await this.$resolve(fetchTopicsRequest))?.data?.topicsVerbatims
        return topicsVerbatims
      },
      contextTopicRequest(fetchTopicsRequest) {
        if (this.hasTextAnalysisEnabled) {
          return fetchTopicsRequest.select({
            topics_verbatims: [
              { COUNT_DISTINCT_verbatims_voter_id: { as: "nbTopic" } },
              { MAX_text_analysis_topics_id: { as: "topicId" } },
              { MAX_topic_public_name: { as: "topicName" } }
            ]
          }).group(
            [ 'text_analysis_topic_id' ]
          ).order(
            [[ 'COUNT_text_analysis_topic_id', 'DESC' ], ['MAX_text_analysis_topic_id', 'ASC']]
          )
        } else {
          return fetchTopicsRequest.select({
            topics_verbatims: [
              { COUNT_DISTINCT_verbatims_voter_id: { as: "nbTopic" } },
              { MAX_topic_id: { as: "topicId" } },
              { MAX_topics_name: { as: "topicName" } }
            ]
          }).group(
            [ 'topic_id' ]
          ).order(
            [ 'COUNT_topic_id', 'DESC' ]
          )
        }
      }
    }
  }
</script>

<style lang="stylus" >
  .topics-verbatim-topics-widget
    .v-data-footer
        padding-left: 14px
        padding-right: 14px
        padding-top: 4px
        padding-bottom: 4px
        display: flex
        align-items: center
        flex-wrap: nowrap

        .v-data-footer__select
          display: none
        .v-data-footer__pagination
          display: none
        .v-data-footer__icons-before
          margin-left: auto
</style>
