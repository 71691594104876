import Vue from 'vue'
import { mapGetters } from 'vuex'
import { sqlDate } from '@vue/plugins/helpers/dates'
import _cloneDeep from 'lodash/cloneDeep'
import NisMixin from '@statistics/shared/nis_mixin'
import { voterAvgRepartitions } from '@platform/pages/dashboard/shared/constants.js'

/*
  Currently, NPS campaign indicators are not loaded since they are not used in the
  platform. But some code already exist like `nb_review_by_nps_campaign_indicator_`
  based request or `NetImpactScoreNpsCampaignIndicatorSummary` NIS summary.
*/
export default {
  mixins: [
    NisMixin,
  ],
  computed: {
    ...mapGetters([
      'placeIdsScope',
      'dashboardFilterRequest',
      'datesScope',
      'currentUserCampaignScoreIndicator',
      'dashboardFilterBase64',
      'requestedTransactionalUnit',
      'netImpactScoreSummariesLoaded',
      'dashboardFilterReady',
    ])
  },
  data: () => {
    return {
      _campaignScoreIndicatorByCampaignBaseData: {},
      _campaignIndicatorsByCampaignBaseData: {},
      campaignIndicatorsMixin_campaignScoreIndicatorByCampaign: {},
      campaignIndicatorsMixin_campaignIndicatorsByCampaign: {},
      _campaignScoreIndicatorByCampaignBaseData_isLoading: {},
      _campaignIndicatorsByCampaignBaseData_areLoading: {},
      _campaignScoreIndicatorByCampaign_isLoading: {},
      _campaignIndicatorsByCampaign_areLoading: {},
    }
  },
  watch: {
    dashboardFilterBase64() {
      this.$data._campaignScoreIndicatorByCampaignBaseData = {}
      this.$data._campaignIndicatorsByCampaignBaseData = {}
      this.campaignIndicatorsMixin_campaignScoreIndicatorByCampaign = {}
      this.campaignIndicatorsMixin_campaignIndicatorsByCampaign = {}
    },
    requestedTransactionalUnit() {
      this.campaignIndicatorsMixin_campaignScoreIndicatorByCampaign = {}
      this.campaignIndicatorsMixin_campaignIndicatorsByCampaign = {}
    },
    netImpactScoreSummariesLoaded(loaded) {
      if (loaded) {
        this.campaignIndicatorsMixin_campaignScoreIndicatorByCampaign = {}
        this.campaignIndicatorsMixin_campaignIndicatorsByCampaign = {}
      }
    }
  },
  methods: {
    async _previousCampaignScoreIndicatorScore(campaign) {
      const campaignScoreIndicator = this.currentUserCampaignScoreIndicator(campaign.id)

      let request = this.dashboardFilterRequest
                        .select({
                          voters: [
                            { [campaignScoreIndicator.formulaToColumn]: { as: 'score' } }
                          ]
                        })
                        .where({ campaign_id: campaign.id })
                        .dateBetween(
                          sqlDate(this.datesScope.comparedDateBegin),
                          sqlDate(this.datesScope.comparedDateEnd, '23:59:59')
                        )

      if (this.placeIdsScope && this.placeIdsScope.length) {
        request = request.where({
          place_campaigns_place_id: this.placeIdsScope,
        })
      }

      return (await this.$resolve(request, "_previousCampaignScoreIndicatorScore")).first()?.score
    },
    async _previousPeriodScoreByCampaignIndicatorId(campaign) {
      let request = this.dashboardFilterRequest
                        .select({
                          voter_avg_campaign_indicators: [
                            { AVG_avg_campaign_indicator: { as: 'score' } }
                          ]
                        })
                        .where({ campaign_id: campaign.id })
                        .dateBetween(
                          sqlDate(this.datesScope.comparedDateBegin),
                          sqlDate(this.datesScope.comparedDateEnd, '23:59:59')
                        )
                        .group(['campaign_indicator_id'])

      if (this.placeIdsScope && this.placeIdsScope.length) {
        request = request.where({
          place_campaigns_place_id: this.placeIdsScope,
        })
      }

      return (await this.$resolve(request))?.data || {}
    },
    async _loadCampaignScoreIndicatorBaseData(campaign) {
      if (!this.dashboardFilterReady) {
        return
      }
      // Data has already been loaded.
      if (this.$data._campaignScoreIndicatorByCampaignBaseData[campaign.id] !== undefined) {
        return
      }
      // Set lock.
      if (this.$data._campaignScoreIndicatorByCampaignBaseData_isLoading[campaign.id]) {
        return
      }
      this.$data._campaignScoreIndicatorByCampaignBaseData_isLoading[campaign.id] = true
      // Prepare request.
      const campaignScoreIndicator = this.currentUserCampaignScoreIndicator(campaign.id)
      const field = `nb_review_by_${campaignScoreIndicator.formulaToColumn}`
      const gradeCondition = campaign.avgScoreScale.max === 5 ?
        [
          { [field]: { ...voterAvgRepartitions.detractors5, as: 'nbDetractors' }},
          { [field]: { ...voterAvgRepartitions.neutrals5, as: 'nbNeutrals' }},
          { [field]: { ...voterAvgRepartitions.promoters5, as: 'nbPromoters' }}
        ] :
        [
          { [field]: { ...voterAvgRepartitions.detractors10, as: 'nbDetractors' }},
          { [field]: { ...voterAvgRepartitions.neutrals10, as: 'nbNeutrals' }},
          { [field]: { ...voterAvgRepartitions.promoters10, as: 'nbPromoters' }}
        ]
      let request = this.dashboardFilterRequest
                        .select({
                          voters: gradeCondition.concat([
                            { [campaignScoreIndicator.formulaToColumn]: { as: 'score' } }
                          ])
                        })
                        .where({ campaign_id: campaign.id })
                        .dateBetween(
                          sqlDate(this.datesScope.dateBegin),
                          sqlDate(this.datesScope.dateEnd, '23:59:59')
                        )
      if (this.placeIdsScope && this.placeIdsScope.length) {
        request = request.where({
          place_campaigns_place_id: this.placeIdsScope,
        })
      }
      // Load indicator.
      const result = (await this.$resolve(request, "_loadCampaignScoreIndicatorBaseData")).first()

      if (result) {
        result.nbReview = (result?.nbDetractors || 0) + (result?.nbNeutrals || 0) + (result?.nbPromoters || 0)
      }

      // That campaign indicator is unavailable.
      if (result === null) {
        Vue.set(
          this.$data._campaignScoreIndicatorByCampaignBaseData,
          campaign.id,
          null
        )
      } else {
        // Load evolution data.
        if (result !== null) {
          const previousCampaignScoreIndicatorScore =
            await this._previousCampaignScoreIndicatorScore(campaign)
          if (previousCampaignScoreIndicatorScore) {
            result.evolution = result.score - previousCampaignScoreIndicatorScore
          }
        }
        // Set indicator for that campaign.
        Vue.set(
          this.$data._campaignScoreIndicatorByCampaignBaseData,
          campaign.id,
          {
            ...result,
            id: campaignScoreIndicator.id,
            name: campaignScoreIndicator.formulaToName,
            indicatorType: 'campaignScoreIndicator',
            campaign: campaign,
            campaignName: campaign.name,
            formulaToName: campaignScoreIndicator.formulaToName,
            formulaToColumn: campaignScoreIndicator.formulaToColumn
          }
        )
      }
      // Remove lock.
      this.$data._campaignScoreIndicatorByCampaignBaseData_isLoading[campaign.id] = false
    },
    async _loadCampaignIndicatorsBaseData(campaign) {
      // Data has already been loaded.
      if (this.$data._campaignIndicatorsByCampaignBaseData[campaign.id] !== undefined) {
        return
      }
      // Set lock.
      if (this.$data._campaignIndicatorsByCampaignBaseData_areLoading[campaign.id]) {
        return
      }
      this.$data._campaignIndicatorsByCampaignBaseData_areLoading[campaign.id] = true
      // Prepare request.
      const gradeCondition = campaign.avgScoreScale.max === 5 ?
        [
          { ['nb_review_by_avg_campaign_indicator']: { min: 0, max: 3, condition: '[..[', as: 'nbDetractors' }},
          { ['nb_review_by_avg_campaign_indicator']: { min: 3, max: 4, condition: '[..[', as: 'nbNeutrals' }},
          { ['nb_review_by_avg_campaign_indicator']: { min: 4, max: 5, condition: '[..]', as: 'nbPromoters' }}
        ] :
        [
          { ['nb_review_by_avg_campaign_indicator']: { min: 0, max: 7, condition: '[..[', as: 'nbDetractors' }},
          { ['nb_review_by_avg_campaign_indicator']: { min: 7, max: 9, condition: '[..[', as: 'nbNeutrals' }},
          { ['nb_review_by_avg_campaign_indicator']: { min: 9, max: 10, condition: '[..]', as: 'nbPromoters' }}
        ]
      let request = this.dashboardFilterRequest
                        .select({
                          voter_avg_campaign_indicators: gradeCondition.concat([
                            { MAX_campaign_indicator_id: { as: 'id' } },
                            { AVG_avg_campaign_indicator: { as: 'score' } },
                            { MAX_campaign_indicator_name: { as: 'name' } },
                            { nb_review: { as: 'nbReview' } }
                          ])
                        })
                        .where({ campaign_id: campaign.id })
                        .dateBetween(
                          sqlDate(this.datesScope.dateBegin),
                          sqlDate(this.datesScope.dateEnd, '23:59:59')
                        )
                        .order(
                          [
                            ['AVG_avg_campaign_indicator', "desc"],
                            ['nb_review', "desc"]
                          ]
                        )
                        .group(['campaign_indicator_id'])

      if (this.placeIdsScope && this.placeIdsScope.length) {
        request = request.where({
          place_campaigns_place_id: this.placeIdsScope,
        })
      }
      // Load indicators.
      const results = (await this.$resolve(request, "_loadCampaignIndicatorsBaseData")).first()

      const indicators = {}
      // Load evolution data.
      for (const result of results) {
        indicators[result.id] = {
          ...result,
          indicatorType: 'campaignIndicator',
          campaign: campaign,
          campaignName: campaign.name
        }
        const previousPeriodScoreByCampaignIndicatorId =
          await this._previousPeriodScoreByCampaignIndicatorId(campaign)
        const previousScore = previousPeriodScoreByCampaignIndicatorId[result.id]?.score
        if (previousScore) {
          indicators[result.id].evolution = result.score - previousScore
        }
      }
      // Set indicators for that campaign.
      Vue.set(
        this.$data._campaignIndicatorsByCampaignBaseData,
        campaign.id,
        indicators
      )
      // Remove lock.
      this.$data._campaignIndicatorsByCampaignBaseData_areLoading[campaign.id] = false
    },
    async campaignIndicatorsMixin_loadCampaignScoreIndicator(campaign) {
      // Data has already been loaded.
      if (this.campaignIndicatorsMixin_campaignScoreIndicatorByCampaign[campaign.id] !== undefined) {
        return
      }
      // Set lock.
      if (this.$data._campaignScoreIndicatorByCampaign_isLoading[campaign.id]) {
        return
      }
      this.$data._campaignScoreIndicatorByCampaign_isLoading[campaign.id] = true
      // Load indicator.
      await this.nisMixin_loadNetImpactScoreSummariesByCampaignId()
      await this._loadCampaignScoreIndicatorBaseData(campaign)
      // The data is not available yet, let's defer.
      if (this.$data._campaignScoreIndicatorByCampaignBaseData[campaign.id] === undefined) {
        this.$data._campaignScoreIndicatorByCampaign_isLoading[campaign.id] = false
        setTimeout(
          () => this.campaignIndicatorsMixin_loadCampaignScoreIndicator(campaign),
          500
        )
        return
      }
      // Load NIS data.
      let indicator = _cloneDeep(this.$data._campaignScoreIndicatorByCampaignBaseData[campaign.id])
      if (this.nisMixin_canUseNisFeature([campaign]) &&
          this.nisMixin_avgScoreSummary(campaign)
      ) {
        const nisSummary = this.nisMixin_avgScoreSummary(campaign)

        indicator = {
          ...indicator,
          nisRoiSatisfaction: this.nisMixin_roiSatisfaction(indicator, nisSummary),
          nisOpportunity: this.nisMixin_transactionalUnitToEarn(indicator, nisSummary),
          nisPlaceReferences: this.nisMixin_getPlaceReferences(nisSummary),
          nisPlaceReferencesGroupName: nisSummary.groupName,
          nisPlaceReferencesGroupCategoryName: nisSummary.groupCategoryName,
          nisEmoji: this.nisMixin_transactionalUnit([campaign]).emoji,
          nisSummary: nisSummary,
          nisSatisfactionRates: this.nisMixin_satisfactionRates(
                                  indicator,
                                  nisSummary
                                )
        }
      } else {
        indicator = {
          ...indicator,
          emoji: null,
          nisSummary: null,
          nisRoiSatisfaction: null,
          nisSatisfactionRates: null
        }
      }
      // Set indicator.
      Vue.set(
        this.campaignIndicatorsMixin_campaignScoreIndicatorByCampaign,
        campaign.id,
        indicator
      )
      // Remove lock.
      this.$data._campaignScoreIndicatorByCampaign_isLoading[campaign.id] = false
    },
    async campaignIndicatorsMixin_loadCampaignIndicators(campaign) {
      // Data has already been loaded.
      if (this.campaignIndicatorsMixin_campaignIndicatorsByCampaign[campaign.id] !== undefined) {
        return
      }
      // Set lock.
      if (this.$data._campaignIndicatorsByCampaign_areLoading[campaign.id]) {
        return
      }
      this.$data._campaignIndicatorsByCampaign_areLoading[campaign.id] = true
      // Load indicators
      await this.nisMixin_loadNetImpactScoreSummariesByCampaignId()
      await this._loadCampaignIndicatorsBaseData(campaign)
      // The data is not available yet, let's defer.
      if (this.$data._campaignIndicatorsByCampaignBaseData[campaign.id] === undefined) {
        this.$data._campaignIndicatorsByCampaign_areLoading[campaign.id] = false
        setTimeout(
          () => this.campaignIndicatorsMixin_loadCampaignIndicators(campaign),
          500
        )
        return
      }
      // Load NIS data.
      let indicators = _cloneDeep(this.$data._campaignIndicatorsByCampaignBaseData[campaign.id])
      if (this.nisMixin_canUseNisFeature([campaign])) {
        for (const [key, indicator] of Object.entries(indicators)) {
          const hasAvgCampaignIndicatorSummary =
            !!Object.values(this.nisMixin_avgCampaignIndicatorSummaries(campaign)).length &&
            this.nisMixin_avgCampaignIndicatorSummaries(campaign)[indicator.id] !== undefined

          if (hasAvgCampaignIndicatorSummary) {
            const nisSummary =
              this.nisMixin_avgCampaignIndicatorSummaries(campaign)[indicator.id]

            indicators[key].nisRoiSatisfaction = this.nisMixin_roiSatisfaction(indicator, nisSummary)
            indicators[key].nisOpportunity = this.nisMixin_transactionalUnitToEarn(indicator, nisSummary)
            indicators[key].nisPlaceReferences = this.nisMixin_getPlaceReferences(nisSummary)
            indicators[key].nisPlaceReferencesGroupName = nisSummary.groupName
            indicators[key].nisPlaceReferencesGroupCategoryName = nisSummary.groupCategoryName
            indicators[key].nisEmoji = this.nisMixin_transactionalUnit([campaign]).emoji
            indicators[key].nisSummary = nisSummary
            indicators[key].nisSatisfactionRates = this.nisMixin_satisfactionRates(
                              indicator,
                              nisSummary
                            )

          } else {
            indicators[key].emoji = null
            indicators[key].nisSummary = null
            indicators[key].nisOpportunity = null
            indicators[key].nisSatisfactionRates = null
          }
        }
      }
      // Set indicators for that campaign.
      Vue.set(
        this.campaignIndicatorsMixin_campaignIndicatorsByCampaign,
        campaign.id,
        indicators
      )
      // Remove lock.
      this.$data._campaignIndicatorsByCampaign_areLoading[campaign.id] = false
    }
  }
}
