import { mapGetters } from 'vuex'
import _sortBy from 'lodash/sortBy'

export default {
  computed: {
    ...mapGetters([
      'dashboardFilterRequest',
      'sqlComparedDateBegin',
      'sqlComparedDateEnd',
      'mainCampaign',
      'groupRankingPlaceIds',
      'networkRankingPlaceIds',
      'dashboardFilterReady',
    ]),
    themeById() {
      if (this.themeAndTopicReady) {
        const themes = [...(this.themes || [])]

        _sortBy(themes, (theme) => -(theme.feelingScore || 0)).forEach((theme, rank) => {
          const themeCompared = this.themesComparedByThemeId[theme.id]

          theme.topics = this.topics.filter(topic => topic.theme.id == theme.id)
          theme.themeFeelingRank = rank
          theme.topics.forEach(topic => {
            topic.themeFeelingRank = rank
          })

          if (themeCompared) {
            theme.reviewTotalEvolution = this.$helpers.numeric.safeDiff(themeCompared.reviewTotal, theme.reviewTotal, 0)
            theme.reviewRateEvolution = this.$helpers.numeric.safeDiff(themeCompared.reviewRate, theme.reviewRate, 1)
            theme.feelingScoreEvolution = this.$helpers.numeric.safeDiff(themeCompared.feelingScore, theme.feelingScore, 0)
          }
        })

        return themes.reduce((h, theme) => { h[theme.id] = theme; return h }, {})
      }
    },
    formatedTopics() {
      let formatedTopics = [...(this.topics || [])]

      if (this.topicsComparedByTopicId && this.topics) {
        formatedTopics.forEach((topic) => {
          const topicCompared = this.topicsComparedByTopicId[topic.topic.id]

          if (topicCompared) {
            topic.reviewTotalEvolution = this.$helpers.numeric.safeDiff(topicCompared.reviewTotal, topic.reviewTotal, 0)
            topic.reviewRateEvolution = this.$helpers.numeric.safeDiff(topicCompared.reviewRate, topic.reviewRate, 1)
            topic.feelingScoreEvolution = this.$helpers.numeric.safeDiff(topicCompared.feelingScore, topic.feelingScore, 0)
          }
        })
      }

      return formatedTopics
    },
    baseRequest() {
      let request = this.dashboardFilterRequest

      request = request.where({
        campaign_id: this.campaign.id
      })

      return request
    },
    hasTextAnalysisEnabled() {
      return this.campaign?.hasTextAnalysisEnabled || this.mainCampaign?.hasTextAnalysisEnabled
    },
    topicIdColumn() {
      return this.hasTextAnalysisEnabled ? 'text_analysis_topics_id' : 'topic_id'
    },
    themeIdColumn() {
      return this.hasTextAnalysisEnabled ? 'text_analysis_themes_id' : 'topic_semantics_id'
    },
    topicPublicNameColumn() {
      return this.hasTextAnalysisEnabled ? 'topic_public_name' : 'topics_name'
    },
    themePublicNameColumn() {
      return this.hasTextAnalysisEnabled ? 'theme_public_name' : 'topic_semantics_name'
    },
    themeAndTopicReady() {
      return this.topics && this.topicsComparedByTopicId && this.themes && this.themesComparedByThemeId
    }
  },
  asyncComputed: {
    topics: {
      lazy: true,
      async get() {
        const topics = await this.fetchtopics(this.baseRequest, null, null, this.topicIdColumn)

        return topics
      }
    },
    themes: {
      lazy: true,
      async get() {
        const themes = await this.fetchtopics(this.baseRequest, null, null, this.themeIdColumn)
        return themes
      }
    },
    groupTopics: {
      lazy: true,
      async get() {
        const baseRequest = this.baseRequest.where({ place_campaigns_place_id: this.groupRankingPlaceIds })
        const topics = await this.fetchtopics(baseRequest, null, null, this.topicIdColumn)

        return topics
      }
    },
    networkTopics: {
      lazy: true,
      async get() {
        const baseRequest = this.baseRequest.where({ place_campaigns_place_id: this.networkRankingPlaceIds })
        const topics = await this.fetchtopics(baseRequest, null, null, this.topicIdColumn)

        return topics
      }
    },
    groupThemesById: {
      lazy: true,
      async get() {
        const baseRequest = this.baseRequest.where({ place_campaigns_place_id: this.groupRankingPlaceIds })
        const themes = await this.fetchtopics(baseRequest, null, null, this.themeIdColumn)

        return themes.reduce((h, theme) => { h[theme.id] = theme; return h }, {})
      }
    },
    networkThemesById: {
      lazy: true,
      async get() {
        const baseRequest = this.baseRequest.where({ place_campaigns_place_id: this.networkRankingPlaceIds })
        const themes = await this.fetchtopics(baseRequest, null, null, this.themeIdColumn)

        return themes.reduce((h, theme) => { h[theme.id] = theme; return h }, {})
      }
    },
    topicsComparedByTopicId: {
      lazy: true,
      async get() {
        if (!this.dashboardFilterReady) {
          return
        }

        const sqlComparedDateBegin = this.sqlComparedDateBegin
        const sqlComparedDateEnd = this.sqlComparedDateEnd
        const request = this.baseRequest

        if (sqlComparedDateBegin || sqlComparedDateEnd) {
          return (await this.fetchtopics(request, sqlComparedDateBegin, sqlComparedDateEnd, this.topicIdColumn)).reduce((h, topicsVerbatim) => {
            h[topicsVerbatim.topic.id] = topicsVerbatim
            return h
          }, {})
        }
      }
    },
    themesComparedByThemeId: {
      lazy: true,
      async get() {
        if (!this.dashboardFilterReady) {
          return
        }

        const sqlComparedDateBegin = this.sqlComparedDateBegin
        const sqlComparedDateEnd = this.sqlComparedDateEnd
        const request = this.baseRequest

        if (sqlComparedDateBegin || sqlComparedDateEnd) {
          const result = await this.fetchtopics(request, sqlComparedDateBegin, sqlComparedDateEnd, this.themeIdColumn)

          return result.reduce((h, topicsVerbatim) => {
            h[topicsVerbatim.theme.id] = topicsVerbatim
            return h
          }, {})
        }
      }
    }
  },
  methods: {
    getZoomedData(topics, themeById, theme, topic) {
      if (this.themeAndTopicReady && topics && themeById) {
        if (topic) {
          const result = topics.find(topicData => topicData?.topic?.id === topic?.id)
          return result?.topic ? { ...result,  ...result?.topic, ...{ type: "topic" } } : undefined
        } else if (theme) {
          return { ...themeById[theme.id], ...{ type: "theme" } }
        }
      }
    },
    async fetchtopics(request, dateBegin = null, dateEnd = null, group = null) {
      let topicDataByFeelingByTopicRequest = (dateBegin || dateEnd ? request.dateBetween(dateBegin, dateEnd) : request)
      const topics = []

      topicDataByFeelingByTopicRequest = topicDataByFeelingByTopicRequest.select({
        main_topics_verbatims: [
          { COUNT_DISTINCT_voters_id: { as: 'reviewTotal' } },
          { [`MAX_${this.topicIdColumn}`]: { as: 'topicId' } },
          { [`MAX_${this.topicPublicNameColumn}`]: { as: 'topicName' } },
          { [`MAX_${this.themeIdColumn}`]: { as: 'themeId' } },
          { [`MAX_${this.themePublicNameColumn}`]: { as: 'themeName' } },
          { total_feeling_: { feeling: '0', as: 'totalPositive' } },
          { total_feeling_: { feeling: '1', as: 'totalNeutral' } },
          { total_feeling_: { feeling: '2', as: 'totalNegative' } },
          { rate_feeling_: { feeling: '0', as: 'ratePositive' } },
          { rate_feeling_: { feeling: '1', as: 'rateNeutral' } },
          { rate_feeling_: { feeling: '2', as: 'rateNegative' } }
        ]
      }).group([
        group
      ])

      if (this.hasTextAnalysisEnabled) {
        topicDataByFeelingByTopicRequest = topicDataByFeelingByTopicRequest.where({ text_analysis_topics_id: this.campaign.textAnalysisTopicIds })
      }

      let topicDataByTopicId = (await this.$resolve(topicDataByFeelingByTopicRequest))?.data
      let totalVoterRequest = (dateBegin || dateEnd ? request.dateBetween(dateBegin, dateEnd) : request).select({
        voters: [{ COUNT_id: { as: 'totalVoter' } }]
      }).where({ main_verbatim_id: { is_not_null: true } })

      const totalVoter = (await this.$resolve(totalVoterRequest)).first()?.totalVoter

      for (const [topicId, topicData] of Object.entries(topicDataByTopicId)) {
        const topic = { topic: {}, theme: {}, reviewTotal: 0, reviewDistribution: { nbPromoters: 0, nbNeutrals: 0, nbDetractors: 0 } }

        topic.topic = { id: topicData.topicId, name: topicData.topicName }
        topic.theme = { id: topicData.themeId, name: topicData.themeName }

        topic.reviewDistribution.nbPromoters = topicData.totalPositive
        topic.reviewDistribution.nbNeutrals = topicData.totalNeutral
        topic.reviewDistribution.nbDetractors = topicData.totalNegative

        topic.totalTopic = topicData.totalPositive + topicData.totalNegative + topicData.totalNeutral


        topic.reviewTotal = topic.topic.reviewTotal = topicData.reviewTotal
        topic.feelingScore = (topic.totalTopic > 0 ? Math.round(topic.reviewDistribution.nbPromoters * 100.0 / topic.totalTopic) : 0)
        topic.reviewRate = (totalVoter > 0 ? topic.reviewTotal * 100.0 / totalVoter : 0)
        topic.totalVoter = totalVoter
        topics.push(topic)
      }

      if (group === this.themeIdColumn) {
        topics.forEach((theme) => {
          theme.id = theme.theme.id
          theme.name = theme.theme.name
        })
      }

      return topics
    }
  }
}
