<template>
  <v-btn-toggle class="voter-score-filter" v-model="mainScoreFilter" color="accent" :rounded="false" tile borderless>
    <v-btn value="promoters" color="white" :ripple="false" class="score-btn">
      <v-icon class="f-18" color="success">mdi-emoticon-happy-outline</v-icon>
    </v-btn>

    <v-btn value="neutrals" color="white" :ripple="false" tile class="score-btn">
      <v-icon class="f-18" color="lightgrey">mdi-emoticon-neutral-outline</v-icon>
    </v-btn>

    <v-btn value="detractors" color="white" :ripple="false" tile class="score-btn score-btn-last">
      <v-icon class="f-18" color="error">mdi-emoticon-sad-outline</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
  export default {
    props: [ 'value' ],
    name: "VoterScoreFilter",
    data() {
      return {
        mainScoreFilter: this.value?.mainScoreFilter
      }
    },
    computed: {
      filters() {
        return {
          mainScoreFilter: this.mainScoreFilter
        }
      }
    },
    methods: {
      updateFilters() {
        this.$emit("input", this.filters)
      },
    },
    watch: {
      filters: {
        handler: "updateFilters",
        deep: true,
      }
    },
  }
</script>

<style lang='stylus' scoped>
  .voter-score-filter
    .score-btn
      border-left: 1px solid #d3d3d3 !important;

    .score-btn-last
      border-right: 1px solid #d3d3d3 !important;
</style>
