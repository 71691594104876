<template>
  <div class="voter-survey">
    <v-skeleton-loader
      v-if="!currentSurvey || !selectedVoterSurvey"
      type="list-item-three-line"
    />
    <w-timeline-element
      v-else
      left
    >
      <template v-slot:avatar>
        <VoterAvatar
          :voter="voter"
        />
      </template>
      <template v-slot:title>
        <VoterHeader
          :product="product"
          :voter="voter"
        />
      </template>

      <template v-if="$vuetify.breakpoint.smAndDown" v-slot:subtitle>
        <div
          class="d-flex align-center flex-wrap f-12 c-lightgrey"
        >
          <div
            class="mr-2"
          >
            <w-date
              :suffix="voter.complete == false ? `(${$t('incomplete_review')})` : ''"
              :date="voter.createdAt"
              :displayTime="true"
              :icon="voter.isRenotation ? 'mdi-update' : 'mdi-clock-outline'"
              font="f-12"
            />
          </div>
          <div class="d-flex align-center ga-1 mr-2">
            <w-icon
              icon="map-marker"
              :icon-style="{ fontSize: '12px' }"
            />
            <span class="text-truncate flex-shrink-1">{{ voter.placeName }}</span>
          </div>

          <div class="d-flex align-center ga-1">
            <v-icon class="f-12">mdi-vote-outline</v-icon>
            <span class="flex-shrink-0">{{ voter.campaignName }}</span>
          </div>
        </div>
      </template>

      <template v-slot:actions>
        <w-drop-down-menu
          :items="actions"
          icon="mdi-dots-horizontal"
          :showArrow="false"
        />
      </template>

      <template v-slot:content>
        <div
          class="d-flex pa-4 rounded-lg bg-bodygrey"
        >
          <Survey
            :voter="voter"
            :selectedVoterSurvey="selectedVoterSurvey"
            :gBrandName="gBrandName"
            :fieldsCustomization="fieldsCustomization"
            :prospectFields="prospectFields"
            :fromLanguage="fromLanguage"
          />
        </div>
      </template>
    </w-timeline-element>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Survey from './Survey'
import VoterHeader from '../VoterHeader'
import VoterAvatar from '../VoterAvatar'

const TRANSLATED = "translated"
const ORIGINAL = "original"

export default {
  name: 'VoterSurvey',
  components: {
    Survey,
    VoterHeader,
    VoterAvatar
  },
  props: {
    voter: { required: true },
    fromLanguage: { required: false, type: String },
    selectedVoterSurvey: { required: false, default: null },
    product: { required: false },
    gBrandName: { required: true },
    fieldsCustomization: { required: true },
    prospectFields: { required: true },
  },
  computed: {
    ...mapGetters([
      'currentVoterPdfExportLink',
      'currentSurvey',
      'currentUserLocale',
      'currentUserFeedbackContent',
      'currentUserId',
      'currentAdmin'
    ]),
    avatarContent() {
      return this.voter.author?.charAt(0)
    },
    actions() {
      const actions = [
        {
          title: this.$t('voterSurvey.send'),
          onClick: this.send,
          class: 'edit',
          icon: 'mdi-pencil'
        },
        {
          title: this.$t('voterSurvey.download'),
          onClick: this.download,
          class: 'delete',
          icon: 'mdi-download'
        },
        {
          title: this.$t('delete'),
          onClick: this.destroyVoter,
          class: 'delete',
          icon: 'mdi-trash-can-outline',
          displayCondition: this.currentAdmin
        },
        {
          title: this.originalTitle(),
          onClick: this.handleSelectTranslation,
          params: { feedbackContent: ORIGINAL },
          class: 'delete',
          icon: 'mdi-translate',
          displayCondition: this.shouldDisplayTranslateActions && this.currentUserFeedbackContent === TRANSLATED
        },
        {
          title: this.translatedTitle(),
          onClick: this.handleSelectTranslation,
          params: { feedbackContent: TRANSLATED },
          class: 'delete',
          icon: 'mdi-translate',
          displayCondition: this.shouldDisplayTranslateActions && this.currentUserFeedbackContent === ORIGINAL
        }
      ]

      return actions.filter((action) => action.displayCondition === undefined || action.displayCondition)
    },
    shouldDisplayTranslateActions() {
      return this.currentUserLocale !== this.fromLanguage && this.fromLanguage != 'u_'
    }
  },
  methods: {
    send() {
      this.$store.dispatch('setActionsTab', 'send_feedback')
      this.$store.dispatch('showVoterActions')
    },
    download() {
      window.open(this.currentVoterPdfExportLink, '_blank')
    },
    async destroyVoter() {
      await this.$api.wizville.voters.destroy(this.voter.uniqueId)
      this.$store.dispatch('notifySuccess')
      // this.$router.go({ name: 'Feedbacks', params: { status: 'all', subStatus: 'all' } })
      await this.$store.dispatch("destroyVoterContext")
    },
    originalTitle() {
      const suffix = this.fromLanguage ? `(${this.fromLanguage.toUpperCase()})` : ''

      return `${this.$t("feedbacks_translate_select_original")} ${suffix}`
    },
    mobileTranslatedTitle() {
      const suffix = this.currentUserLocale && this.fromLanguage ?
        `(${this.fromLanguage.toUpperCase()} ➝ ${this.currentUserLocale.toUpperCase()})` : ''

      return `${this.$t("feedbacks_translate_button_translated")} ${suffix}`
    },
    translatedTitle() {
      const suffix = this.currentUserLocale && this.fromLanguage ?
        `(${this.fromLanguage.toUpperCase()} ➝ ${this.currentUserLocale.toUpperCase()})` : ''

      return `${this.$t("feedbacks_translate_select_translated")} ${suffix}`
    },
    async handleSelectTranslation(params) {
      this.updateFeedbackContent(params.feedbackContent)
    },
    async updateFeedbackContent(feedbackContent) {
      const response = await this.$api.wizville.users.update(this.currentUserId, { feedback_content: feedbackContent });

      if (response.status === "ok") {
        this.$store.dispatch('setFeedbackContent', feedbackContent)
      }
    }
  }
}
</script>
