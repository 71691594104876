import _concat from "lodash/concat"
import _uniqBy from "lodash/uniqBy"
import _startCase from "lodash/startCase"
import store from '@platform/store'
import { i18n } from '@i18n/setup'
import { voterAvgRepartitions } from '@platform/pages/dashboard/shared/constants.js'

export function sourceForColumnName(source) {
  return _startCase(source).replace(' ', '')
}

function scoreDetractorConditions(avgScoreScale, value = 'nbDetractors') {
  const isFiveScore = avgScoreScale?.max === 5

  return isFiveScore ?
    { ...voterAvgRepartitions.detractors5, as: value } :
    { ...voterAvgRepartitions.detractors10, as: value }
}

function scoreNeutralConditions(avgScoreScale, value = 'nbNeutrals') {
  const isFiveScore = avgScoreScale?.max === 5

  return isFiveScore ?
    { ...voterAvgRepartitions.neutrals5, as: value } :
    { ...voterAvgRepartitions.neutrals10, as: value }
}

function scorePromoterConditions(avgScoreScale, value = 'nbPromoters') {
  const isFiveScore = avgScoreScale?.max === 5

  return isFiveScore ?
    { ...voterAvgRepartitions.promoters5, as: value } :
    { ...voterAvgRepartitions.promoters10, as: value }
}

function columnsToHeaders(columns, columnsOptions) {
  const headers = []

  for (const columnName of columns) {
    let header = null

    const columnOptions = columnsOptions[columnName]
    if (['placeName', 'g_place_name'].includes(columnName)) {
      header = {
        text: store.getters.currentLexicon.translate('place', 'place'),
        value: "placeName",
        loadingGroup: 'base',
        pdfMinWidth: 200,
        align: 'left',
        displayAs: 'ranked_item',
        basedColumn: {
          brand_voters: [
            { 'MAX_place_name': { as: 'placeName' } },
            { 'MAX_place_codes': { as: 'placeCodes' } }
          ]
        }
      }
    } else if (['groupName'].includes(columnName)) {
      header = {
        text: i18n.t('groupName'),
        value: "groupName",
        loadingGroup: "base",
        pdfMinWidth: 80,
        displayAs: 'ranked_item',
        basedColumn: {
          brand_voters: {
            'MAX_group_name': { as: 'groupName' }
          }
        }
      }
    } else if (['voterStringInfoValue'].includes(columnName)) {
      header = {
        text: i18n.t('voterStringInfoValue'),
        value: "voterStringInfoValue",
        loadingGroup: "base",
        basedColumn: {
          brand_voters: {
            'MAX_voter_string_info_value': { as: 'voterStringInfoValue' }
          }
        }
      }
    } else if (['avg_score', 'avgScore', 'g_average_mark', 'avg_satisfaction'].includes(columnName)) {
      const value = 'avgSatisfaction'
      const nbReviewValue = `nbReviewSatisfaction`
      const options = columnsOptions['g_average_mark']

      header = {
        text: options?.text || i18n.t('average_note'),
        value: value,
        nbReviewValue: nbReviewValue,
        presenter: 'avg',
        displayAs: 'score',
        loadingGroup: 'avg_score',
        basedColumn: {
          brand_voters: [
            { 'avg_satisfaction': { as: value } },
            { 'nb_review_with_avg_score': { as: nbReviewValue } }
          ]
        }
      }
    } else if (['avg_satisfaction_answer_repartition'].includes(columnName)) {
      header = {
        text: i18n.t('answer_repartition'),
        value: "avgSatisfactionAnswerRepartition",
        presenter: 'round',
        displayAs: 'repartition',
        nbDetractors: 'nbDetractors',
        nbNeutrals: 'nbNeutrals',
        nbPromoters: 'nbPromoters',
        scoreType: 'avgSatisfaction',
        loadingGroup: 'avg_satisfaction_answer_repartition',
        basedColumn: {
          brand_voters: [
            { 'avg_satisfaction': { as: 'avgSatisfactionAnswerRepartition' } },
            { "nb_review_by_avg_satisfaction": scoreDetractorConditions(columnOptions.avgScoreScale) },
            { "nb_review_by_avg_satisfaction": scoreNeutralConditions(columnOptions.avgScoreScale) },
            { "nb_review_by_avg_satisfaction": scorePromoterConditions(columnOptions.avgScoreScale) }
          ]
        }
      }
    } else if (['nps_score', 'npsScore', 'g_nps'].includes(columnName)) {
      const value = "npsScore"
      const nbReviewValue = "nbReviewNpsScore"

      header = {
        text: (store.getters.isLcl ? "IRC" : "NPS"),
        value: value,
        nbReviewValue: nbReviewValue,
        loadingGroup: "nps",
        presenter: "round",
        nbDetractors: 'nbDetractors',
        nbNeutrals: 'nbNeutrals',
        nbPromoters: 'nbPromoters',
        displayAs: 'repartition',
        scoreType: 'nps',
        basedColumn: {
          brand_voters: [
            { 'avg_nps': { as: value } },
            { 'nb_review_by_nps': { min: 0, max: 7, condition: '[..[', as: 'nbDetractors' } },
            { 'nb_review_by_nps': { min: 7, max: 9, condition: '[..[', as: 'nbNeutrals' } },
            { 'nb_review_by_nps': { min: 9, max: 10, condition: '[..]', as: 'nbPromoters' } }
          ]
        }
      }
    }
    else if (columnName.match(/^avg_nps_campaign_\d+$/)) {
      const campaignId = Number(columnName.match(/avg\_nps\_campaign\_(\d+)/)[1])
      const value = `avgNpsCampaign${campaignId}`
      const nbReviewValue = `nbReviewCampaign${campaignId}`
      const nbDetractors = `nbDetractors${campaignId}`
      const nbNeutrals = `nbNeutrals${campaignId}`
      const nbPromoters = `nbPromoters${campaignId}`

      header = {
        text: columnOptions.text,
        value: value,
        nbReviewValue: nbReviewValue,
        nbDetractors: nbDetractors,
        nbNeutrals: nbNeutrals,
        nbPromoters: nbPromoters,
        loadingGroup: "nps",
        presenter: "round",
        displayAs: 'repartition',
        scoreType: 'nps',
        basedColumn: {
          brand_voters: [
            { 'avg_nps_campaign_': { campaign_id: campaignId, as: value } },
            { "nb_review_by_nps_campaign_": { ...scoreDetractorConditions(columnOptions.avgScoreScale, nbDetractors), campaign_id: campaignId } },
            { "nb_review_by_nps_campaign_": { ...scoreNeutralConditions(columnOptions.avgScoreScale, nbNeutrals), campaign_id: campaignId } },
            { "nb_review_by_nps_campaign_": { ...scorePromoterConditions(columnOptions.avgScoreScale, nbPromoters), campaign_id: campaignId } }
          ]
        }
      }
    }
    else if (['g_promoters'].includes(columnName)) {
      header = {
        text: `👤 ${i18n.t('promoters')}`,
        value: "nbPromoters",
        loadingGroup: "nps",
        basedColumn: {
          brand_voters: {
            "nb_review_by_nps": { min: 9, max: 10, condition: '[..]', as: 'nbPromoters' }
          }
        }
      }
    }
    else if (['g_neutral'].includes(columnName)) {
      header = {
        text: `👤 ${i18n.t('neutrals')}`,
        value: "nbNeutrals",
        loadingGroup: "nps",
        basedColumn: {
          brand_voters: {
            "nb_review_by_nps": { min: 7, max: 9, condition: '[..[', as: 'nbNeutrals' }
          }
        }
      }
    }
    else if (['g_detractors'].includes(columnName)) {
      header = {
        text: `👤 ${i18n.t('detractors')}`,
        value: "nbDetractors",
        loadingGroup: "nps",
        basedColumn: {
          brand_voters: {
            "nb_review_by_nps": { min: 0, max: 7, condition: '[..[', as: 'nbDetractors' }
          }
        }
      }
    }
    else if (['nbReview', 'g_answers'].includes(columnName)) {
      header = {
        text: i18n.t('feedback'),
        value: "totalReview",
        loadingGroup: "base",
        basedColumn: {
          brand_voters: {
            'nb_review': { as: 'totalReview' }
          }
        }
      }
    } else if (['g_processed_insatisfactions'].includes(columnName)) {
      header = {
        text: i18n.t('feedbackStatus.processed'),
        value: "nbProcessedInsatisfactions",
        loadingGroup: "insatisfaction",
        basedColumn: {
          brand_voters: {
            'nb_processed_insatisfactions': { as: 'nbProcessedInsatisfactions' }
          }
        }
      }
    } else if (['g_unprocessed_insatisfactions'].includes(columnName)) {
      header = {
        text: i18n.t('feedbackStatus.notProcessed'),
        value: "nbUnprocessedInsatisfactions",
        loadingGroup: "insatisfaction",
        basedColumn: {
          brand_voters: {
            'nb_unprocessed_insatisfactions': { as: 'nbUnprocessedInsatisfactions' }
          }
        }
      }
    } else if (['g_processing_time'].includes(columnName)) {
      header = {
        text: i18n.t('g_processing_time'),
        value: "insatisfactionsAvgProcessingTime",
        loadingGroup: "insatisfaction",
        presenter: "hour",
        displayAs: "hour",
        basedColumn: {
          brand_voters: {
            'insatisfactions_avg_processing_time': {
              as: 'insatisfactionsAvgProcessingTime',
              date_begin: store.getters.sqlDateBegin,
              date_end: store.getters.sqlDateEnd
            }
          }
        }
      }
    } else if (['g_oldest_processing_time'].includes(columnName)) {
      header = {
        text: i18n.t('g_oldest_processing_time'),
        value: "insatisfactionsMaxProcessingTime",
        loadingGroup: "insatisfaction",
        presenter: "hour",
        displayAs: "hour",
        basedColumn: {
          brand_voters: {
            'insatisfactions_max_processing_time': { as: 'insatisfactionsMaxProcessingTime' }
          }
        }
      }
    } else if (['g_insatisfactions'].includes(columnName)) {
      header = {
        text: store.getters.currentLexicon.translate('insatisfactions', 'insatisfactions')+" (%)",
        value: "insatisfactionsRate",
        loadingGroup: "insatisfaction",
        presenter: "rate",
        displayAs: "rate",
        basedColumn: {
          brand_voters: {
            'rate_insatisfaction': { as: 'insatisfactionsRate' }
          }
        }
      }
    } else if (columnName.match(/^avg_score_campaign_\d+$/)) {
      const campaignId = Number(columnName.match(/avg\_score\_campaign\_(\d+)/)[1])
      const value = `avgScoreCampaign${campaignId}`
      const nbReviewValue =  `nbReviewCampaign${campaignId}`

      header = {
        text: columnOptions.text,
        value: value,
        nbReviewValue: nbReviewValue,
        loadingGroup: "base",
        presenter: 'avg',
        displayAs: 'score',
        avgScoreScale: columnOptions.avgScoreScale,
        basedColumn: {
          brand_voters: [
            { avg_score_campaign_: { campaign_id: campaignId, as: value } },
            { nb_review_campaign_with_avg_score_: { campaign_id: campaignId, as: nbReviewValue } },
          ]
        }
      }
    } else if (columnName.match(/^plateform_nb_voters\_\w+$/)) {
      const source = columnName.match(/plateform_nb_voters\_(\w+)$/)[1]
      const value = `nbReview${sourceForColumnName(source)}`
      const sourceText =
        {
          not_social: i18n.t('source.not_social'),
          social: i18n.t('source.social'),
        }[source] || source

      header = {
        text: i18n.t('review_by_source', { source: sourceText }),
        value: value,
        loadingGroup: "source_indicators",
        basedColumn: {
          brand_voters: {
            'nb_review_from_source_': { source: source, as: value }
          }
        }
      }
    } else if (columnName.match(/^avg_voters_\w+_answer_repartition$/)) {
      const source = columnName.match(/avg_voters_(\w+)_answer_repartition$/)[1]
      const value = `avgScore${sourceForColumnName(source)}Repartition`
      const nbReviewValue = `nbReview${sourceForColumnName(source)}Rep`

      header = {
        text: i18n.t('answer_repartition'),
        value: value,
        nbReviewValue: nbReviewValue,
        presenter: 'round',
        displayAs: 'repartition',
        nbDetractors: 'nbDetractors',
        nbNeutrals: 'nbNeutrals',
        nbPromoters: 'nbPromoters',
        loadingGroup: 'source_indicators',
        scoreType: 'avgSatisfaction',
        basedColumn: {
          brand_voters: [
            { 'avg_score_from_source_': { source: source, as: value } },
            { "nb_review_by_avg_satisfaction_source_": { ...scoreDetractorConditions(columnOptions.avgScoreScale), ...{ source: source } } },
            { "nb_review_by_avg_satisfaction_source_": { ...scoreNeutralConditions(columnOptions.avgScoreScale), ...{ source: source } } },
            { "nb_review_by_avg_satisfaction_source_": { ...scorePromoterConditions(columnOptions.avgScoreScale), ...{ source: source } } }
          ]
        }
      }
    } else if (columnName.match(/^avg_voters_\w+$/)) {
      const source = columnName.match(/avg_voters_(\w+)$/)[1]
      const value = `avgScoreSource${sourceForColumnName(source)}`
      const nbReviewValue = `nbReviewSource${sourceForColumnName(source)}`
      const avgScoreScale = ['social', 'pages_jaunes', 'facebook', 'google', 'trip_advisor'].includes(source) ? { min: 1, max: 5 } : null
      const sourceText =
        {
          not_social: i18n.t('source.not_social'),
          social: i18n.t('source.social'),
        }[source] || source

      header = {
        text: i18n.t('avg_grad_by_source', { source: sourceText }),
        value: value,
        nbReviewValue: nbReviewValue,
        loadingGroup: "source_indicators",
        presenter: "avg",
        displayAs: 'score',
        avgScoreScale: avgScoreScale,
        basedColumn: {
          brand_voters: [
            { 'avg_score_from_source_': { source: source, as: value } },
            { 'nb_review_from_source_': { source: source, as: nbReviewValue } }
          ]
        }
      }
      // ex: i_2456
    } else if (columnName.match(/^i\_\d+$/)) {
      const campaignIndicatorId = Number(columnName.match(/i\_(\d+)/)[1])
      const as = `avgCampaignIndicator${campaignIndicatorId}`
      const nbReviewValue = `nbReviewCampaignIndicator${campaignIndicatorId}`

      header = {
        text: i18n.t('avg_grad_by_campaign_indicator', { campaignIndicator: campaignIndicatorId }),
        value: as,
        nbReviewValue: nbReviewValue,
        loadingGroup: `avg_campaign_indicators_${campaignIndicatorId}`,
        presenter: "avg",
        displayAs: 'score',
        basedColumn: {
          brand_voters: [
            { 'nb_review_avg_campaign_indicator_': { campaign_indicator_id: campaignIndicatorId, as: nbReviewValue } },
            { 'avg_score_campaign_indicator_': { campaign_indicator_id: campaignIndicatorId, as: as } }
          ]
        }
      }
      // ex: voter_avg_campaign_indicator_2456
    } else if (columnName.match(/^voter_avg_campaign_indicator\_\d+$/)) {
      const campaignIndicatorId = Number(columnName.match(/voter_avg_campaign_indicator\_(\d+)/)[1])
      const as = `avgCampaignIndicator${campaignIndicatorId}`
      const nbReviewValue = `nbReviewCampaignIndicator${campaignIndicatorId}`

      header = {
        text: columnOptions.text,
        value: as,
        nbReviewValue: nbReviewValue,
        loadingGroup: `avg_campaign_indicators_${campaignIndicatorId}`,
        presenter: "avg",
        displayAs: 'score',
        basedColumn: {
          brand_voters: [
            { 'nb_review_avg_campaign_indicator_': { campaign_indicator_id: campaignIndicatorId, as: nbReviewValue } },
            { 'avg_score_campaign_indicator_': { campaign_indicator_id: campaignIndicatorId, as: as } }
          ]
        }
      }
    } else if (columnName.match(/^i\_\d+_answer_repartition$/)) {
      const campaignIndicatorId = Number(columnName.match(/^i\_(\d+)_answer_repartition$/)[1])
      const as = `campaignIndicator${campaignIndicatorId}AnswerRepartition`

      header = {
        text: i18n.t('answer_repartition'),
        value: as,
        loadingGroup: `answer_repartition_campaign_indicators_${campaignIndicatorId}`,
        presenter: 'avg',
        scoreType: 'avgSatisfaction',
        displayAs: 'repartition',
        nbDetractors: 'nbDetractors',
        nbNeutrals: 'nbNeutrals',
        nbPromoters: 'nbPromoters',
        basedColumn: {
          brand_voters: [
            { 'avg_score_campaign_indicator_': { campaign_indicator_id: campaignIndicatorId, as: as } },
            { "nb_review_by_avg_score_campaign_indicator_": { ...scoreDetractorConditions(columnOptions.avgScoreScale), ...{ campaign_indicator_id: campaignIndicatorId } } },
            { "nb_review_by_avg_score_campaign_indicator_": { ...scoreNeutralConditions(columnOptions.avgScoreScale), ...{ campaign_indicator_id: campaignIndicatorId } } },
            { "nb_review_by_avg_score_campaign_indicator_": { ...scorePromoterConditions(columnOptions.avgScoreScale), ...{ campaign_indicator_id: campaignIndicatorId } } }
          ]
        }
      }
    } else if (columnName.match(/^t\_\d+$/)) {
      const topicId = Number(columnName.match(/t\_(\d+)/)[1])
      const as = `avgTopic${topicId}`
      const nbReviewValue = `nbReviewTopic${topicId}`

      header = {
        text: i18n.t('avg_grad_by_topic', { topic: topicId }),
        value: as,
        nbReviewValue: nbReviewValue,
        loadingGroup: `avg_topics_${topicId}`,
        presenter: "avg",
        displayAs: 'score',
        basedColumn: {
          brand_voters: [
            { 'nb_review_topic_': { topic_id: topicId, as: nbReviewValue } },
            { 'avg_topic_': { topic_id: topicId, as: as } }
          ]
        }
      }
    } else if (columnName.match(/^t\_\d+_answer_repartition$/)) {
      const topicId = Number(columnName.match(/^t\_(\d+)_answer_repartition$/)[1])
      const as = `topic${topicId}AnswerRepartition`

      header = {
        text: i18n.t('answer_repartition'),
        value: as,
        loadingGroup: `answer_repartition_topics_${topicId}`,
        presenter: "avg",
        scoreType: 'avgSatisfaction',
        displayAs: 'repartition',
        nbDetractors: 'nbDetractors',
        nbNeutrals: 'nbNeutrals',
        nbPromoters: 'nbPromoters',
        basedColumn: {
          brand_voters: [
            { 'avg_topic_': { topic_id: topicId, as: as } },
            { "nb_review_by_avg_score_topic_": { ...scoreDetractorConditions(columnOptions.avgScoreScale), ...{ topic_id: topicId } } },
            { "nb_review_by_avg_score_topic_": { ...scoreNeutralConditions(columnOptions.avgScoreScale), ...{ topic_id: topicId } } },
            { "nb_review_by_avg_score_topic_": { ...scorePromoterConditions(columnOptions.avgScoreScale), ...{ topic_id: topicId } } }
          ]
        }
      }
    } else if (columnName.match(/^s\_\d+$/)) {
      const topicSemanticId = Number(columnName.match(/s\_(\d+)/)[1])
      const as = `avgTopicSemantic${topicSemanticId}`
      const nbReviewValue = `nbReviewTopicSemantic${topicSemanticId}`

      header = {
        text: i18n.t('avg_grad_by_topic_semantic', { topicSemantic: topicSemanticId }),
        value: as,
        nbReviewValue: nbReviewValue,
        loadingGroup: `avg_topic_semantics_${topicSemanticId}`,
        presenter: "avg",
        displayAs: 'score',
        basedColumn: {
          brand_voters: {
            'avg_topic_semantic_': { topic_semantic_id: topicSemanticId, as: as },
            'nb_review_topic_semantic_': { topic_semantic_id: topicSemanticId, as: nbReviewValue }
          }
        }
      }
    } else if (['g_google_mark_question_answer'].includes(columnName)) {
      header = {
        text: 'Google',
        value: 'placeUrl',
        loadingGroup: 'base',
        displayAs: 'url',
        basedColumn: { brand_voters: { 'MAX_place_gmaps_url': { as: 'placeUrl' } } },
      }
    }

    if (header) {
      headers.push({ ...header, ...(columnOptions || {}) })
    } else {
      console.error(`'${columnName}' column name not supported`)
    }
  }

  return headers
}

export function getHeaders(baseColumn, rankingColumn, columns, columnsOptions = {}) {
  const allColumns = _concat([ baseColumn, rankingColumn], columns)

  return _uniqBy(
      columnsToHeaders(allColumns, (columnsOptions || {})),
    (header) => header.value)
}

export function mapBasedColumnsByLoadingGroup(basedColumns) {
  const basedColumnsByLoadingGroup = { }

  basedColumns.forEach(column => {
    const groupKey = column.loadingGroup || 'auto'
    basedColumnsByLoadingGroup[groupKey] = basedColumnsByLoadingGroup[groupKey] || {}

    for (const [tableName, tableColumns] of Object.entries(column.basedColumn)) {
      basedColumnsByLoadingGroup[groupKey][tableName] = basedColumnsByLoadingGroup[groupKey][tableName] || []

      if (Array.isArray(tableColumns)) {
        basedColumnsByLoadingGroup[groupKey][tableName] = _concat(basedColumnsByLoadingGroup[groupKey][tableName], tableColumns)
      } else {
        for (const [columnKey, columnValue] of Object.entries(tableColumns)) {
          basedColumnsByLoadingGroup[groupKey][tableName].push(
            { [columnKey]: columnValue }
          )
        }
      }
    }
  })

  return basedColumnsByLoadingGroup
}

export function mergeBasedColumns(basedColumns1, basedColumns2) {
  const mergedBasedColumns = { }

  for (const [tableName, tableColumns] of _concat(Object.entries(basedColumns1), Object.entries(basedColumns2))) {
    mergedBasedColumns[tableName] = mergedBasedColumns[tableName] || []

    if (Array.isArray(tableColumns)) {
      mergedBasedColumns[tableName] = _concat(mergedBasedColumns[tableName], tableColumns)
    } else {
      for (const [columnKey, columnValue] of Object.entries(tableColumns)) {
        mergedBasedColumns[tableName].push(
          { [columnKey]: columnValue }
        )
      }
    }
  }

  return mergedBasedColumns
}
