<template>
  <WStatsWidget
    v-if="topic === undefined"
    :title="title"
    :cols="12"
    :loading="true"
    :empty="false"
  />
  <PlaceRankingWidget
    v-else
    :title="title"
    :campaign="campaign"
    :basedRequest="basedRequest"
    :indicatorColumn="indicatorColumn"
    :indicatorAlias="indicatorAlias"
    :cols="12"
    :displayRank="false"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import PlaceRankingWidget from '@statistics/shared/widgets/PlaceRankingWidget'

  export default {
    name: "TopicPlaceRankingWidget",
    components: {
      PlaceRankingWidget
    },
    props: {
      campaign: { type: Object, required: true },
      topic: { type: Object },
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
        'placeIds',
      ]),
      title() {
        return this.topic === undefined ?
               this.$t('ranking') :
               `${this.$t('ranking')} - ${this.topic.name}`
      },
      basedRequest() {
        return this.dashboardFilterRequest.select({
          brand_voters: [
            this.indicatorColumn,
            { nb_review_topic_: { topic_id: this.topic.id, as: 'nbReview' } },
            { place_campaigns_place_id: { as: 'placeId' } },
            { MAX_place_name: { as: 'placeName' } }
          ]
        })
      },
      indicatorColumn() {
        return { avg_topic_: { topic_id: this.topic.id, as: this.indicatorAlias } }
      },
      indicatorAlias() {
        return 'score'
      }
    }
  }
</script>
