<template>
  <div class="fs-12 text-center">
    <v-skeleton-loader class="pa-3" v-if="lrmPosition === undefined" type="paragraph" />
    <w-position-widget
      v-else
      :position="lrmPosition && lrmPosition.relativeRank"
      :evolution="lrmPosition && lrmPosition.relativeRankEvolution"
      :displayEvolution="displayEvolution"
      :nrTooltipText="lrmPosition?.rankMessage"
      :iconInfoColor="getIconInfoColor"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "PositionGauge",
    components: {},
    props: {
      periodType: { required: true, type: String },
      competitorMode: { required: true, type: String },
      competitorScope: { type: String },
      lmBrandIds: {},
      active: {},
    },
    asyncComputed: {
      lrmPosition: {
        default: undefined,
        async get() {
          if (!this.dashboardFilterReady) {
            return
          }
          const request = this.lrmDashboardFilterRequest.select({
            [this.currentDashboardBasedTable]: [{
              lrm_position: { params: {
                period: this.periodType,
                competitor_mode: this.competitorMode,
                competitor_scope: this.competitorScope,
                place_id: this.placeIdsScope,
                lm_place_id: this.active?.id,
                date_begin: this.datesScope.dateBegin,
                date_end: this.datesScope.dateEnd,
                compared_date_begin: this.datesScope.comparedDateBegin,
                compared_date_end: this.datesScope.comparedDateEnd,
                competitor_lm_brand_ids: this.lmBrandIds
              } }
            }]
          })
          return (await this.$resolve(request)).first()?.lrmPosition || {}
        }
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboardBasedTable",
        "lrmDashboardFilterRequest",
        "placeIdsScope",
        "datesScope",
        "currentDashboardParams",
        "currentDashboard",
        'dashboardFilterReady',
      ]),
      relativeRankOn() {
        return this.lrmPosition?.relativeRankOn
      },
      displayEvolution() {
        return this.datesScope.key !== 'genesis';
      },
      getIconInfoColor() {
        return ['not_scraped'].includes(this.lrmPosition?.rankStatus) ? 'c-yellow-darken' : 'c-lightgrey'
      }
    }
  }
</script>
