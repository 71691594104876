<template>
  <PlaceRankingWidget
    :title="title"
    :basedRequest="basedRequest"
    :indicatorColumn="indicatorColumn"
    :indicatorAlias="indicatorAlias"
    :cols="12"
    :displayRank="false"
  >
  </PlaceRankingWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import PlaceRankingWidget from '@statistics/shared/widgets/PlaceRankingWidget'

  export default {
    name: "SocialPlaceRankingWidget",
    components: {
      PlaceRankingWidget
    },
    props: {
      source: { required: true }
    },
    data() {
      return {}
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
        'placeIds',
      ]),
      title() {
        const sourceTitle = this.$t(`source_${this.source}`)

        return `${this.$t('ranking')} - ${sourceTitle}`
      },
      basedRequest() {
        return this.dashboardFilterRequest.select({
          brand_voters: [
            this.indicatorColumn,
            { 'nb_review_from_source_': { source: this.source, as: 'nbReview' } },
            { place_campaigns_place_id: { as: 'placeId' } },
            { MAX_place_name: { as: 'placeName' } }
          ]
        })
      },
      indicatorColumn() {
        return { avg_score_from_source_: { source: this.source, as: this.indicatorAlias } }
      },
      indicatorAlias() {
        return 'score'
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
