<template>
  <WStatsWidget
    class="campaign-indicators-table-widget"
    :title="title || $t('statistics_widget_indicators_ranking_title')"
    :subtitle="displayedSubtitle"
    :height="widgetHeight"
    :cols="cols"
    :loading="campaignIndicatorsData.length == 0"
    contentWidth="100%"
    :justifyCenter="false"
  >
    <template v-if="campaignIndicatorsData.length > 0" #options>
      <div class="d-flex">
        <w-drop-down-menu
          v-if="nisMixin_canUseNisFeature(eligibleCampaigns)"
          v-model="nisMixin_transactionalUnitsSelectElementValue"
          :items="nisMixin_availableTransactionalUnits(eligibleCampaigns)"
          :title="nisMixin_transactionalUnit(eligibleCampaigns).label"
          :emoji="nisMixin_transactionalUnit(eligibleCampaigns).emoji"
          :showGroupTitle="false"
        />
        <w-drop-down-menu
          :items="exportOptions"
          icon="mdi-download"
        />
      </div>
    </template>
    <template #content>
      <v-data-table
        :headers="headers"
        :items="campaignIndicatorsData"
        :items-per-page="40"
        hide-default-footer
        mobile-breakpoint="0"
        class="campaign-indicators-table v-datatable-without-y-borders"
        :item-class= "rowClasses"
        item-key="key"
        @click:row="zoomOnCampaignIndicator"
        :sort-by="sortBy"
        sort-desc
      >
        <template v-slot:header.nisOpportunity="{ header }">
          <HeaderWithTooltip
            :title="header.text"
            :tooltip="header.tooltip"
          />
        </template>

        <template v-slot:item.campaignName="{ item }">
          <div class="d-flex align-center ga-4">
            <w-zoom-button
              v-if="$vuetify.breakpoint.smAndUp"
              :highlighted="isItemActive(item)"
              @click="zoomOnCampaignIndicator(item)"
            />
            <div class="d-flex flex-column">
              <span
                class="fs-13 c-primary name"
                :class="{ 'fs-13': $vuetify.breakpoint.smAndDown }"
              >
                {{ item.campaignName }}
              </span>
              <span
                v-if="$vuetify.breakpoint.smAndDown"
                class="f-13 c-primary name"
              >
                {{ item.name }}
              </span>
            </div>
          </div>
        </template>

        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center ga-4">
            <w-zoom-button
              v-if="$vuetify.breakpoint.smAndUp && !isCampaignNameColumnDisplayed"
              :highlighted="isItemActive(item)"
              @click="zoomOnCampaignIndicator(item)"
            />
            <span
              class="f-13 c-primary name"
              :class="{ 'fs-13': !isCampaignNameColumnDisplayed }"
            >
              {{ item.name }}
            </span>
          </div>
        </template>

        <template v-slot:item.score="{ item }">
          <ScoreCell
            :nbReview="item.nbReview"
            :score="item.score.toFixed(1)"
            :evolution="item.evolution"
            :nbPromoters="item.nbPromoters"
            :nbDetractors="item.nbDetractors"
            :nbNeutrals="item.nbNeutrals"
            :repartitionWidth="repartitionWidth"
            :showNbReview="$vuetify.breakpoint.mdAndUp"
          />
        </template>

        <template v-slot:item.nisOpportunity="{ item }">
          <NisOpportunityCell
            :nisItem="item"
          />
        </template>

        <template v-slot:item.nisSatisfactionGoal="{ item }">
          <NisSatisfactionGoalCell
            :nisItem="item"
          />
        </template>
      </v-data-table>
    </template>
  </WStatsWidget>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import _upperFirst from 'lodash/upperFirst'

  import { exportToExcel, formatExcelValues } from '@shared/helpers/export-to-excel.js'
  import Pdf from '@shared/helpers/exportToPdf/pdf'
  import paginateArray from '@shared/helpers/paginate-array'

  import HeaderWithTooltip from '../../shared/Table/HeaderWithTooltip'
  import ScoreCell from '@statistics/shared/Table/ScoreCell'
  import NisOpportunityCell from '@statistics/shared/Table/NisOpportunityCell'
  import NisSatisfactionGoalCell from '@statistics/shared/Table/NisSatisfactionGoalCell'

  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'

  export default {
    name: "CampaignIndicatorsTableWidget",
    mixins: [
      WidgetMixin,
      NisMixin,
    ],
    components: {
      HeaderWithTooltip,
      ScoreCell,
      NisOpportunityCell,
      NisSatisfactionGoalCell
    },
    props: {
      title: { type: String },
      cols: { type: Number, default: 6 },
      selectedCampaignIndicator: { type: Object },
      eligibleCampaigns: { required: true },
      campaignScoreIndicator: { required: true },
      campaignIndicators: { required: true },
    },
    data() {
      return {
        flattenCampaignIndicators: {}
      }
    },
    watch: {
      dashboardFilterBase64() {
        this.flattenCampaignIndicators = {}
      },
      campaignScoreIndicator(newData) {
        for (const [campaignId, indicator] of Object.entries(newData)) {
          Vue.set(
            this.flattenCampaignIndicators,
            `campaign_score_indicator_${campaignId}`,
            indicator
          )
        }
      },
      campaignIndicators(newData) {
        for (const [campaignId, indicators] of Object.entries(newData)) {
          for (const [indicatorId, indicator] of Object.entries(indicators)) {
            Vue.set(
              this.flattenCampaignIndicators,
              `campaign_indicator_${campaignId}_${indicatorId}`,
              indicator
            )
          }
        }
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterBase64',
      ]),
      campaignIndicatorsData() {
        return Object.values(this.flattenCampaignIndicators)
      },
      sortBy() {
        return this.campaignIndicatorsData.some(data => !!data.nisOpportunity) ?
               'nisOpportunity' :
               'score'
      },
      repartitionWidth() {
        return (!this.showNisColumns || this.$vuetify.breakpoint.mdAndUp) ? '160px' : '100px'
      },
      widgetHeight() {
        if (this.campaignIndicatorsData.length == 0) {
          return '150px'
        }

        const headerHeight = 65
        const tableHeaderHeight = 50
        const tableMargins = 16
        const height = headerHeight +
                       tableHeaderHeight +
                       this.campaignIndicatorsData.length * 50 +
                       tableMargins

        return height > 310 ? '310px' : `${height}px`
      },
      exportOptions() {
        return [
          { title: 'PDF', onClick: this.exportToPdf },
          { title: 'Excel', onClick: this.exportToExcel }
        ]
      },
      isCampaignNameColumnDisplayed() {
        return this.eligibleCampaigns.length > 1
      },
      isNameColumnDisplayed() {
        return this.eligibleCampaigns.length === 1 ||
               (
                 this.eligibleCampaigns.length > 1 &&
                 this.$vuetify.breakpoint.mdAndUp
               )
      },
      showNisColumns() {
        return this.nisMixin_canUseNisFeature(this.eligibleCampaigns, false)
      },
      scoreColumnWidth() {
        if (!this.showNisColumns) {
          return null
        }

        if (this.$vuetify.breakpoint.mdAndUp) {
          return '300px'
        }

        return '200px'
      },
      headers() {
        return [
          {
            text: _upperFirst(this.$t('campaign')),
            value: 'campaignName',
            align: 'start',
            cellClass: 'campaign-name-cell',
            displayCondition: this.isCampaignNameColumnDisplayed
          },
          {
            text: this.$t('indicator'),
            value: 'name',
            align: 'start',
            cellClass: 'name-cell',
            displayCondition: this.isNameColumnDisplayed
          },
          {
            text: this.$t('average_mark'),
            value: 'score',
            width: this.scoreColumnWidth
          },
          {
            text: this.nisMixin_opportunityHeaderTitle(this.eligibleCampaigns),
            value: 'nisOpportunity',
            align: 'center',
            tooltip: this.nisMixin_opportunityHelpMessage(this.eligibleCampaigns),
            displayCondition: this.showNisColumns && this.$vuetify.breakpoint.smAndUp
          },
          {
            text: this.$t('campaign_indicators_table_widget_goal'),
            value: 'nisSatisfactionGoal',
            sortable: false,
            displayCondition: this.showNisColumns && this.$vuetify.breakpoint.mdAndUp
          }
        ].filter((header) => header.displayCondition === undefined || header.displayCondition)
      }
    },
    methods: {
      zoomOnCampaignIndicator(campaignIndicator) {
        this.$router.push(
          {
            params: {
              sectionId: campaignIndicator.id,
              subSectionId: campaignIndicator.campaign.id
            }
          }
        )
      },
      scoreForStars(item) {
        if (item.score === null) {
          return 0;
        }

        const displayedsScore = item.campaign.avgScoreScale?.max == 5 ? item.score : item.score / 2

        return Math.round(displayedsScore * 2) / 2;
      },
      isItemActive(item) {
        return item.id === this.selectedCampaignIndicator?.id &&
          item.campaign.id === this.selectedCampaignIndicator?.campaign.id
      },
      rowClasses(item) {
        return this.isItemActive(item) ? 'item active' : 'item'
      },
      exportFileName() {
        const pageName = this.$t(this.$route.name)
        const date = this.$date().locale(this.$i18n.locale).format('ddd DD MMM HH_mm_ss')

        return [pageName, date].join(' - ')
      },
      exportToExcel() {
        this.$store.dispatch("notifyInfo");

        const fileName = this.exportFileName();

        const pageName = this.$t(this.$route.name);
        const sheetName = [pageName].join(' - ');

        const data = this.campaignIndicatorsData.map((campaignIndicator) => {
          const { campaignName, name, score, nbReview, nbDetractors, nbNeutrals, nbPromoters } = campaignIndicator;
          return { campaignName, name, score, nbReview, nbDetractors, nbNeutrals, nbPromoters };
        });

        const formattedDatas = formatExcelValues(data, { score: { round: 1 }, evolution: { round: 2 } })

        const headers = Object.keys(formattedDatas[0]).map((key, index) =>
          this.$t(`statistics_widget_indicators_ranking_headers_${key}`)
        );

        exportToExcel(fileName, sheetName, formattedDatas, headers);
      },
      async exportToPdf() {
        const exportHeader = async() => {
          const title = this.title || this.$t('statistics_widget_indicators_ranking_title')
          await pdf.addRow({}, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(title, { fontSize: fontSize, color: blackColor, font: { style: 'bold' }})
            })
          })
          await pdf.addRow({ marginTop: 5 }, async(row) => {
            await row.addCol({}, async(col) => {
              col.addText(this.displayedSubtitle, { fontSize: fontSize - 2, font: { style: 'bold' }})
            })
          })

          await exportTableHeaders(pdf)
        }

        const exportTableHeaders = async(pdf) => {
          await pdf.addRow({ height: 15, borderColor: borderColor, marginTop: 20 }, async(row) => {

            if (this.eligibleCampaigns.length > 1) {
              await row.addCol({ width: campagnNameWidth }, async(col) => {
                col.addText(this.$t('campagn_name'), { fontSize: fontSize - 2 })
              })
            }

            await row.addCol({ width: nameWidth }, async(col) => {
              col.addText(this.$t('indicator'), { fontSize: fontSize - 2 })
            })

            await row.addCol({ width: scoreWidth, marginLeft: 10 }, async(col) => {
              col.addText(this.$t('average_mark'), { fontSize: fontSize - 2 })
            })

            await row.addCol({ width: repartitionWidth, marginLeft: 10 }, async(col) => {
              col.addText(this.$t('repartition'), { fontSize: fontSize - 2 })
            })
          })
        }

        const exportEvolution = async(evolution, col, decimals = 0) => {
          let displayedEvolution
          let evolutionSign
          let evolutionColor

          if (evolution && evolution != 0) {
            displayedEvolution = evolution > 0 ? evolution : - evolution
            displayedEvolution = displayedEvolution.toFixed(decimals)
            evolutionSign = evolution > 0 ? '▲': '▼'
            evolutionColor = evolution > 0 ? '#65c095': '#c86868'
          } else {
            displayedEvolution = '='
            evolutionSign = ''
            evolutionColor = '#add8e6'
          }

          await col.addRow({}, async(row) => {
            await row.addCol({ marginLeft: 4 }, async(col) => {
              col.addText(evolutionSign, { color: evolutionColor, fontSize: 4, yOffset: 3 })
            })
            await row.addCol({}, async(col) => {
              col.addText(displayedEvolution, { color: evolutionColor, fontSize: fontSize })
            })
          })
        }

        const exportCampaignName = async (item, row) => {
          await row.addCol({ width: campagnNameWidth, height: rowHeight}, async(col) => {
            col.addText(item.campaignName, { fontSize: fontSize, color: blackColor })
          })
        }

        const exportName = async (item, row) => {
          await row.addCol({ width: nameWidth, height: rowHeight}, async(col) => {
            col.addText(item.name, { fontSize: fontSize, color: blackColor })
          })
        }

        const exportScore = async (item, row) => {
          if (item.nbReview) {
            await row.addCol({ width: scoreWidth, marginLeft: 10 }, async(col) => {
              await col.addRow({ marginTop: marginTopWithSubline }, async(row) => {
                await row.addCol({}, async(col) => {
                  await col.addRating(this.scoreForStars(item), { size: 10 })
                })
                await row.addCol({ marginLeft: 5 }, async(col) => {
                  col.addText(item.score.toFixed(1), { fontSize: fontSize, color: blackColor })
                })
                await row.addCol({}, async(col) => {
                  await exportEvolution(item.evolution, col, 2)
                })
              })
              await col.addRow({}, async(row) => {
                await row.addCol({ marginLeft: 2 }, async(col) => {
                  col.addText(
                    `${this.$t('rankingPdfExport.nbReview', { nbReview: item.nbReview })}`,
                    { fontSize: subLineFontSize }
                  )
                })
              })
            })
          }
        }

        const exportRepartition = async (item, row) => {
          if (item.nbReview) {
            await row.addCol({ height: rowHeight, marginLeft: 10 }, async(col) => {
              col.addRepartitionBar({ width: 150, height: 20, promoterCount: item.nbPromoters, neutralCount: item.nbNeutrals, detractorCount: item.nbDetractors })
            })
          }
        }

        const exportFooter = async(pdf) => {
          const pagination = this.$t('rankingPdfExport.pagination', { page: pdf.pageCount, totalPage: paginatedData.length })
          const title = this.title || this.$t('statistics_widget_indicators_ranking_title')
          const footer = [title, this.defaultSubtitle].join(' - ')

          await pdf.addRow({}, async(row) => {
            await row.addCol({ width: '12' }, async(col) => {
              col.addText(footer, { fontSize: fontSize - 2, align: 'right' })
            })
          })
        }

        this.$store.dispatch("notifyInfo")

        const rowHeight = 30
        const campagnNameWidth = '3'
        const nameWidth = this.eligibleCampaigns.length > 1 ? '4' : '5'
        const scoreWidth = this.eligibleCampaigns.length > 1 ? '2' : '3'
        const repartitionWidth = this.eligibleCampaigns.length > 1 ? '3' : '4'
        const fontSize = 8
        const subLineFontSize = 6
        const marginTop = 6
        const marginTopWithSubline = 4
        const blackColor = "#212121"
        const borderColor = "#e0e0e0"
        const fileName = `${this.exportFileName()}.pdf`

        const pdf = new Pdf({
          defaultBodyMargin: { left: 40, top: 0 },
          defaultHeader: { marginLeft: 40, marginTop: 10, content: exportTableHeaders },
          defaultFooter: { height: 25, marginLeft: 40, content: exportFooter }
        })

        const paginatedData = paginateArray(this.campaignIndicatorsData, 43, 42)

        await paginatedData.reduce(async (pagePromise, pageDate, pageIndex) => {
          await pagePromise

          if (pagePromise) {
            await pdf.addPage()
          } else {
            await pdf.addPage({
              header: { marginLeft: 40, marginTop: 20, content: exportHeader }
            })
          }

          await pageDate.reduce(async (itemPromise, item, itemIndex) => {
            await itemPromise

            let rowParams = { height: rowHeight, borders: ['top', 'bottom'], borderColor: borderColor }

            await pdf.addRow(rowParams, async(row) => {
              if (this.eligibleCampaigns.length > 1) {
                await exportCampaignName(item, row)
              }
              await exportName(item, row)
              await exportScore(item, row)
              await exportRepartition(item, row)
            })

          }, undefined)

        }, undefined)

        pdf.save(fileName)
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .campaign-indicators-table-widget
    .campaign-name-cell
      .name
        text-wrap: initial

    .sort-margin
      padding-right: 18px
</style>
