<template>
  <div class="autologin-advanced-search f-14">
    <div class="autologin-advanced-search-title d-flex">
      <div class="align-self-center">{{ this.$t('components.autologin.advanced_search.advanced_search') }}</div>
      <div class="align-self-center">
        <v-icon v-on:click="toggleAdvancedSearch">
          {{ advancedSearchOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </div>
    </div>

    <div v-if="advancedSearchOpen">
      <w-filterable-autocomplete-set
        :primary-list-items="features"
        :secondary-list-items="brands"
        primary-list-label-i18n-key="components.autologin.advanced_search.features"
        secondary-list-label-i18n-key="components.autologin.advanced_search.n_brands_accounts"
        @primary-list-selected-items-changed="featuresListSelectedItemsChanged"
        @secondary-list-selected-items-changed="brandsListSelectedItemsChanged"
      />

      <w-filterable-autocomplete-set
        :primary-list-items="preferences"
        :secondary-list-items="userGroups"
        primary-list-label-i18n-key="components.autologin.advanced_search.rights_notifications"
        secondary-list-label-i18n-key="components.autologin.advanced_search.n_user_groups"
        @primary-list-selected-items-changed="preferencesSelectedItemsChanged"
        @secondary-list-selected-items-changed="userGroupsListSelectedItemsChanged"
      />

      <v-autocomplete
        v-model="selectedUserAttributes"
        :items="userAttributes"
        :label="this.$t('components.autologin.advanced_search.user_attributes')"
        multiple
        clearable
        chips
        small-chips
        deletable-chips
      />

      <v-autocomplete
        v-model="selectedLanguages"
        :items="languages"
        :label="this.$t('components.autologin.advanced_search.user_language')"
        clearable
        chips
        small-chips
        deletable-chips
      />

      <div class="text-end mb-2">
        <v-btn icon @click="exportUsersSearch">
          <v-icon>
            mdi-download
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import WFilterableAutocompleteSet from '@admin/pages/theme/WFilterableAutocompleteSet'
  import { ADVANCED_SEARCH_FEATURES } from "./features"
  import { ADVANCED_SEARCH_RIGHTS } from "./rights"
  import { ADVANCED_SEARCH_NOTIFICATIONS } from "./notifications"
  import { ADVANCED_SEARCH_USER_ATTRIBUTES } from "./user_attributes"
  import { LANGUAGES_NAME } from '@i18n/setup'

  export default {
    name: 'AutologinAdvancedSearch',
    data: () => ({
      advancedSearchOpen: false,
      features: ADVANCED_SEARCH_FEATURES,
      preferences: ADVANCED_SEARCH_RIGHTS.concat(ADVANCED_SEARCH_NOTIFICATIONS),
      userAttributes: ADVANCED_SEARCH_USER_ATTRIBUTES,
      brands: [],
      allBrands: [],
      userGroups: [],
      allUserGroups: [],
      selectedBrands: [],
      selectedFeatures: [],
      selectedPreferences: [],
      selectedUserAttributes: [],
      selectedLanguages: []
    }),
    components: {
      WFilterableAutocompleteSet
    },
    computed: {
      languages() {
        return Object.entries(LANGUAGES_NAME).map(([id, locale]) => {
          return { "value": id, "text": this.$t(`languages.${locale}`) }
        }).sort(
          (a, b) => a.text.localeCompare(b.text)
        ).filter(
          localeLanguage => !localeLanguage.text.includes('languages.')
        )
      }
    },
    methods: {
      toggleAdvancedSearch() {
        if(this.allBrands.length == 0) this.fetchBrands()
        if(this.allUserGroups.length == 0) this.fetchUserGroups()
        this.advancedSearchOpen = !this.advancedSearchOpen
      },
      toggleLoading(value) {
        this.$emit("toggleLoading", value)
      },
      brandsListSelectedItemsChanged(selectedBrands){
        this.selectedBrands = selectedBrands
        this.$emit("advancedSearchSelectedBrandsChanged", selectedBrands)
        this.filterUserGroups()
      },
      userGroupsListSelectedItemsChanged(selectedUserGroups){
        this.$emit("advancedSearchSelectedUserGroupsChanged", selectedUserGroups)
      },
      featuresListSelectedItemsChanged(selectedFeatures) {
        this.selectedFeatures = selectedFeatures
        this.filterBrands()
      },
      preferencesSelectedItemsChanged(selectedPreferences) {
        this.selectedPreferences = selectedPreferences
        this.filterUserGroups()
      },
      async fetchUserGroups() {
        this.toggleLoading(true)

        const request = this.$adminBasedRequest()
                            .select({ user_groups: ["id", "brand_and_group_name", "sector"] })
                            .order(['sector', 'asc'])

        const response = await this.$resolve(request)
        const userGroupsByJob = Object.groupBy(response.data?.userGroups, ({ sector }) => sector)

        const allUserGroups = []
        Object.keys(userGroupsByJob).forEach(sector => {
          allUserGroups.push({ header: sector })

          Object.values(userGroupsByJob[sector]).sort((a, b) => a.brandAndGroupName.localeCompare(b.brandAndGroupName)).forEach(userGroup => {
            allUserGroups.push({ value: userGroup.id, text: userGroup.brandAndGroupName })
          })
        })

        this.userGroups = this.allUserGroups = allUserGroups

        this.toggleLoading(false)
      },
      async filterUserGroups() {
        this.toggleLoading(true)
        let request = this.$adminBasedRequest().select({ user_groups: ['id']})

        if (this.selectedBrands && this.selectedBrands.length > 0) {
          request = request.where({ brand_id: this.selectedBrands })
        }

        if (this.selectedPreferences && Object.keys(this.selectedPreferences).length > 0) {
          request = request.where({ with_preferences: { enabled: this.selectedPreferences } })
        }

        const response = await this.$resolve(request)
        const userGroupsFilteredItemsIds = Object.values(response.data?.userGroups).map( (item) => item.id )
        this.userGroups = this.allUserGroups.filter(userGroup => userGroupsFilteredItemsIds.includes(userGroup.value) || userGroup.header != undefined)
        this.toggleLoading(false)
      },
      async fetchBrands() {
        this.toggleLoading(true)
        const request = this.$adminBasedRequest()
                                  .select({ brands: ["id", "name"]})
                                  .where({ monitored: true })
                                  .order(['name', 'asc'])
        const response = await this.$resolve(request)
        this.brands = this.allBrands = response.data?.brands?.map(
          (brand) => ({ value: brand.id, text: brand.name })
        )
        this.toggleLoading(false)
      },
      async filterBrands() {
        this.toggleLoading(true)
        const request = this.$adminBasedRequest().select({ brands: ['id']})
                                               .where({ monitored: true, with_features: { enabled: this.selectedFeatures } })
                                               .group('id')

        const response = await this.$resolve(request)
        const secondaryListFilteredItemsIds = Object.values(response.data).map( (item) => item.id )
        this.brands = this.allBrands.filter(brand => secondaryListFilteredItemsIds.includes(brand.value))
        this.toggleLoading(false)
      },
      async exportUsersSearch() {
        this.$emit("advancedSearchExport")
      }
    },
    watch: {
      selectedUserAttributes() {
        this.$emit("advancedSearchSelectedUserAttributesChanged", this.selectedUserAttributes)
      },
      selectedLanguages() {
        this.$emit("advancedSearchSelectedLanguagesChanged", this.selectedLanguages)
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .autologin-advanced-search
    width: 100%

    .v-input >>> .v-input__control .v-input__icon
      cursor: pointer

    .v-input >>> .v-input__control .v-select__slot
      padding-top: 2px

      .v-select__selections
        padding-top: 5px
        padding-bottom: 5px

      .v-chip--select
        margin: 1px 3px 3px 0

    .v-input.v-select.v-text-field:not(.v-text-field--single-line) >>> input
      margin-top: -1px
      padding-top: 0

  .autologin-advanced-search-title
    min-height: 34px
</style>
