<template>
  <v-data-table
    :headers="headers"
    :height="height"
    :items-per-page="-1"
    :items="achievements"
    :loading="loading"
    :loading-text="$t('loading_text')"
    :no-data-text="$t('no_data_text')"
    :no-results-text="$t('no_results_text')"
    class="achievement_list"
    fixed-header
    hide-default-footer
    mobile-breakpoint="800"
    scrollable
  >
    <template v-slot:item.maxClaimedAt="{ value }">
      <div class="d-flex align-center flex-nowrap">
        <v-icon class="f-13 mr-1">mdi-calendar</v-icon>
        <span class="text-capitalize f-13 created-at">
          {{ $helpers.dates.longMonthAndYear(value) }}
        </span>
      </div>
    </template>
    <template v-slot:item.topic="{ item }">
      <v-chip color="primary">
        <span class="f-13 text-truncate">
          {{ item.maxTopicsName }}
        </span>
        <span v-if="item.maxClaimedAt" class="text-uppercase f-9">
          &nbsp;
          {{ $helpers.dates.shortMonthAndYear(item.maxMonth) }}
        </span>
      </v-chip>
    </template>
    <template v-slot:item.achievementActions="{ item }">
      <div class="mt-1">
        <v-chip
          color="primary"
          outlined
          class="d-inline-block mb-1 mr-1"
          v-for="(action, index) in item.achievementActions"
          :key="`${index}-${item.id}-${action.name}`"
        >
          <span v-if="action.emoji">
            <span>{{ action.emoji }}</span>&nbsp;
          </span>
          <span class="f-13">{{ action.name }}</span>
        </v-chip>
      </div>
    </template>
    <template v-if="userId !== null" v-slot:item.editionDeletion="{ item }">
      <v-btn
        outlined
        small
        color="secondary"
        @click="editAchievement(item.id)"
      >
        <v-icon class="c-secondary f-18">
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
        outlined
        small
        color="secondary"
        @click="deleteAchievement(item.id)"
      >
        <v-icon class="c-secondary f-18">
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'AchievementTable',
    props: {
      placeCampaignId: {
        type: Number,
        default: null,
      },
      userId: {
        type: Number,
        default: null,
      },
      topicId: {
        type: Number,
        default: null,
      },
      achievementActionTemplateId: {
        type: Number,
        default: null,
      },
      height: {
        type: String | Number,
        default: '400',
      }
    },
    data() {
      return {
        loading: false,
      }
    },
    methods: {
      editAchievement(id) {
        this.$store.dispatch('setCurrentEditingPlaceCampaignAchievementId', id)
        this.$store.dispatch('setIsOpenClaimAchievementModal', true)
      },
      async deleteAchievement(id) {
        try {
          const response = await this.$api
                                     .wizville
                                     .placeCampaignAchievementsBatch
                                     .destroy(id)
          if (response.status === 'ok') {
            this.$store.dispatch('triggerAchievementsUpdate', response.destroyedAt)
            this.$store.dispatch('notifySuccess', {
              message: this.$t('achievements_leaderboard_tab_place_modal_delete_success'),
              timeout: 3000
            })
          }
        } catch (error) {
          this.$store.dispatch('notifyError', { message: error.message })
        }
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterReady',
        'sqlDatesScope',
        'claimAchievementsEvent',
        'currentLexicon',
      ]),
      headers() {
        let headers

        if (this.achievementActionTemplateId) {
          headers = [
            {
              text: this.currentLexicon.translate('place', 'place'),
              value: 'maxPlacesName',
              align: 'start',
              sortable: !this.$vuetify.breakpoint.mobile,
            },
            {
              text: this.$t('achievements_modal_detail_column'),
              value: 'maxActionsDescription',
              align: 'start',
              cellClass: 'f-13',
              sortable: !this.$vuetify.breakpoint.mobile,
            },
            {
              text: this.$t('achievements_modal_claimed_at_column'),
              value: 'maxClaimedAt',
              align: 'start',
              sortable: !this.$vuetify.breakpoint.mobile,
            }
          ]
        } else {
          headers = [
            {
              text: this.$t('achievements_modal_claimed_at_column'),
              value: 'maxClaimedAt',
              align: 'start',
              sortable: !this.$vuetify.breakpoint.mobile,
            },
            {
              text: this.$t('achievements_modal_achievement_column'),
              value: 'topic',
              align: 'start',
              sortable: !this.$vuetify.breakpoint.mobile,
            },
            {
              text: this.$t('achievements_modal_actions_column'),
              value: 'achievementActions',
              align: 'start',
              sortable: !this.$vuetify.breakpoint.mobile,
            },
            {
              text: this.$t('achievements_modal_detail_column'),
              value: 'maxActionsDescription',
              align: 'start',
              cellClass: 'f-13',
              sortable: !this.$vuetify.breakpoint.mobile,
            }
          ]
        }

        if (this.userId !== null) {
          headers.push({
            value: 'editionDeletion',
            align: 'end',
            sortable: false,
            width: 150,
          })
        }
        return headers
      },
    },
    asyncComputed: {
      achievements: {
        default: [],
        watch: [
          // Needed for `editAchievement` and `deleteAchievement` to update the view after
          // the action is done.
          'claimAchievementsEvent'
        ],
        async get() {
          if (!this.dashboardFilterReady) {
            return
          }
          this.loading = true
          // Prepare WHERE clause.
          const where = { claimed_at: { 'is_not_null': {} } }
          if (this.placeCampaignId !== null) {
            where.place_campaign_id = this.placeCampaignId
          }
          if (this.userId !== null) {
            where.user_id = this.userId
          }
          if (this.topicId !== null) {
            where.topic_id = this.topicId
          }
          if (this.achievementActionTemplateId !== null) {
            where.achievement_action_templates_id = this.achievementActionTemplateId
          }
          // Get data.
          let request =
            this.$basedRequest()
                .select({
                  place_campaign_achievements: [
                    'id',
                    'MAX_places_name',
                    'MAX_claimed_at',
                    'MAX_month',
                    'MAX_topics_name',
                    'MAX_claimed_at',
                    'MAX_actions_description',
                    'MAX_achievement_action_templates_name',
                    'MAX_achievement_action_templates_emoji',
                  ]
                })
                .where({ campaign_id: this.currentDashboardCampaignIds })
                .where(where)
                .group([
                  'id',
                  'achievement_actions_id',
                ])
                .order([['month', 'desc']])
          if (this.achievementActionTemplateId === null) {
            request = request.where({
                  month: { ">=": this.sqlDatesScope.dateBegin },
                  month: { "<=": this.sqlDatesScope.dateEnd }
                })
          }
          const response = await this.$resolve(request)
          // Format data.
          const data = []
          for (const block of Object.values(response?.data || {})) {
            data.push({
              ...Object.values(block)[0],
              achievementActions: Object.values(block).map(row => (
                {
                  name: row.maxAchievementActionTemplatesName,
                  emoji: row.maxAchievementActionTemplatesEmoji,
                }
              ))
            })
          }
          // Return data.
          this.loading = false
          return data
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .achievement_list .created-at
    white-space nowrap

  .achievement_list >>> .v-chip__content
    align-items baseline
    position relative
    top 0.4em
</style>
