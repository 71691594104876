<template>
  <RepartitionWidget
    :basedRequest="basedRequest"
    :loading="loading"
    :title="title"
    :promoters-legend="$t('score_of_x', { x: 5 })"
    :neutrals-legend="$t('score_of_x', { x: 4 })"
    :detractors-legend="$t('score_from_min_to_max', { min: 1, max: 3 })"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import RepartitionWidget from "@statistics/shared/widgets/RepartitionWidget"
  import { voterAvgRepartitions } from '@platform/pages/dashboard/shared/constants.js'

  export default {
    name: "SocialRepartitionWidget",
    components: {
      RepartitionWidget
    },
    props: {
      source: { required: true },
    },
    methods: {
      computeColumn(columnName, min, max, condition, as, source) {
        return {
          [columnName]: {
            min, max, condition, as, source
          }
        }
      },
      computeColumns(columnName) {
        return [
          this.computeColumn(
            columnName,
            voterAvgRepartitions.detractors5.min,
            voterAvgRepartitions.detractors5.max,
            voterAvgRepartitions.detractors5.condition,
            'nbDetractors',
            this.source
          ),
          this.computeColumn(
            columnName,
            voterAvgRepartitions.neutrals5.min,
            voterAvgRepartitions.neutrals5.max,
            voterAvgRepartitions.neutrals5.condition,
            'nbNeutrals',
            this.source
          ),
          this.computeColumn(
            columnName,
            voterAvgRepartitions.promoters5.min,
            voterAvgRepartitions.promoters5.max,
            voterAvgRepartitions.promoters5.condition,
            'nbPromoters',
            this.source
          ),
        ]
      },
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
      ]),
      columns() {
        return this.computeColumns(
          'nb_review_by_avg_satisfaction_source_'
        )
      },
      basedRequest() {
        return this.dashboardFilterRequest.select({
          voters: this.columns
        })
      },
      title() {
        const sourceTitle = this.$t(`source_${this.source}`)

        return `${this.$t('repartition_widget_title')} - ${sourceTitle}`
      }
    },
    data() {
      return {
        loading: true
      }
    },
  }
</script>
