<template>
  <WStatsWidget
    v-if="
      topicsMixin_topics[campaign.id] === undefined ||
      topicsMixin_topics[campaign.id].length
    "
    :border="this.$colors.borderblue"
    :cols="4"
    :load="loadData()"
    :loading="topicsMixin_topics[campaign.id] === undefined"
    class="w-stats-opportunity-widget"
    contentWidth="100%"
    loadingHeight="100px"
  >
    <template #content>
      <WStatsNumber
        :number="topicsRecommendations.length"
        :title="$t('statistics_widget_opportunities_title')"
        :emoji="nisMixin_transactionalUnit([campaign]).emoji"
        :dropdown="true"
      >
        <template #title>
          <div class="d-flex flex-column">
            <span>
              {{ $t('statistics_widget_opportunities_title') }}
            </span>
            <span class="mt-1">
              <span>{{ headerTopicName }}</span>
              <span class="fb-15 c-primary text-no-wrap ml-l">{{ headerTopicNis }}</span>
            </span>
          </div>
        </template>
        <template #dropdown-content>
          <WWeightRepartition
            class="pb-3"
            :items="topicsRecommendations"
            displayPos
          >
            <template #item-name="{ item }">
              <span>
                {{ `${item.position} - ${item.name}`}}
              </span>
            </template>
            <!-- Small trick to replace the slot by an empy content. -->
            <template #item-badge><wbr></template>
            <template #item-score="{ item }">
              <span class="f-13 c-primary text-no-wrap" style="min-width: 60px;">
                {{ `+${item.weight} % ${nisMixin_transactionalUnit([campaign]).emoji}` }}
              </span>
            </template>
            <template #item-link>
              <v-icon small style="color:#C1BFC7">
                mdi-chevron-right
              </v-icon>
            </template>
          </WWeightRepartition>
        </template>
      </WStatsNumber>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import NisMixin from '@statistics/shared/nis_mixin'
import TopicsMixin from '@statistics/shared/topics_mixin'
import _upperFirst from 'lodash/upperFirst'

export default {
  name: 'OpportunityActionsWidget',
  mixins: [
    WidgetMixin,
    NisMixin,
    TopicsMixin,
  ],
  methods: {
    loadData() {
      this.topicsMixin_loadTopics(this.campaign)
    }
  },
  computed: {
    ...mapGetters([
      'mainWizvilleCampaignWithTopics',
      'currentLexicon',
      'currentDashboardScopedCampaigns',
    ]),
    headerTopicName() {
      if (this.topicsRecommendations) {
        return `#1. ${this.topicsRecommendations[0]?.name}`
      }
    },
    headerTopicNis() {
      if (this.topicsRecommendations) {
        return `+${this.topicsRecommendations[0]?.weight} %`
      }
    },
    campaign() {
      return this.mainWizvilleCampaignWithTopics
    },
    eligibleCampaigns() {
      return this.currentDashboardScopedCampaigns.filter(
        campaign => campaign.hasAvgScore && campaign.topics.length >= 1
      )
    },
    topicsRecommendations() {
      if (this.topicsMixin_topics[this.campaign.id] === undefined) return

      return this.topicsMixin_topics[this.campaign.id]
        .map(topic => {
          const nisTopicSummary = this.nisMixin_topicSummaries(this.campaign)[topic.id]

          if (nisTopicSummary) {
            const avgSatisfactionRate = this.$options.filters.toPercent(
              this.nisMixin_placeReferencesAvgSatisfactionRate(
                nisTopicSummary.placeReferences
              )
            )
            const toEarn = this.nisMixin_transactionalUnitToEarn(topic, nisTopicSummary)

            return {
              name: topic.name,
              weight: toEarn,
              weightUnit: '%',
              barColor: "#7CBD98",
              tooltip: this.$t(
                `opportunity_actions_widget_tooltip_${this.nisMixin_transactionalUnit([this.campaign]).nisColumnName}`,
                {
                  toEarn,
                  avgSatisfactionRate,
                  customer: this.currentLexicon.translate('customer', 'customer').toLowerCase()
                }
              ),
              click: () => this.topicsMixin_goToTopic(this.campaign, topic, 'topics'),
            }
          }
        })
        .sort((a, b) => b.weight - a.weight)
        .slice(0,3)
    }
  }
}
</script>

<style lang="stylus">
  @import '~@theme/medias.styl'

  .w-stats-opportunity-widget
    .item-weight-bar
      display: none

      +forBreakpoints(wide) {
        display: table-cell
      }
</style>
