<template>
  <div class="ranking-table-place">
    <RankingTable
      :headers="headers"
      :items="formatedItems"
      :loading="loading"
      :scope="displayedScope"
      :itemRankedColumn="aggregation.baseColumn"
      @scopeChanged="updateSelectedScope"
      :campaign="campaign"
      :rankingRelatedColumn="rankingRelatedColumn"
    />
  </div>
</template>

<script>
  import RankingTable from './RankingTable'
  import RankingTableMixin from "./RankingTable/ranking_table_mixin.js"
  import _concat from "lodash/concat"

  export default {
    name: "RankingTablePlace",
    components: {
      RankingTable
    },
    mixins: [
      RankingTableMixin
    ],
    props: {
      scope: {
        type: String,
        required: true
      },
      staticColumns: {
        required: false
      },
      campaign: {}
    },
    data() {
      return {
        customTableColumns: { based: 'custom_table_ranking_columns', key: 'customTableRankingColumns' },
        aggregation: {
          baseColumn: "placeName",
          key: 'placeId',
          basedColumn: { brand_voters: { 'place_campaigns_place_id': { as: 'placeId'} } }
        },
        ignoredColumnNames: [
          'g_oldest_processing_time'
        ]
      }
    }
  }
</script>
