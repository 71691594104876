<template>
  <WStatsWidget
    class="widget-lmbrand-score"
    :title="title"
    :cols="12"
    height="200px"
    contentWidth="100%"
    :loading="!score"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #content>
      <WStatsScore
        v-if="score"
        :score="score"
        :evolution="comparedScore"
        :displayEvolution="sqlDatesScope && sqlDatesScope.key !== 'genesis'"
        :isFiveScore="true"
        :nbReview="nbReview"
        :nbReviewMessage="nbReviewMessage"
        :decimals="2"
        source="google"
        class="pb-2"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import HeaderSubtitle from '../shared/HeaderSubtitle'

  export default {
    name: "WidgetLmBrandScore",
    components: {
      HeaderSubtitle
    },
    props: ['lmBrandIds', 'countryCode', 'active'],
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterDates',
        'sqlDatesScope',
        'dashboardFilterReady',
      ]),
      score() {
        if (this.lmBrandScore) {
          return this.$helpers.numeric.avg(Object.values(this.lmBrandScore), 'avgScore')
        }
      },
      nbReview() {
        if (this.lmBrandScore) {
          return Math.round(this.$helpers.numeric.avg(Object.values(this.lmBrandScore), 'nbReview'))
        }
      },
      nbReviewMessage() {
        if (!this.active.id) {
          return this.$t('based_on_x_average_feedback', { n: this.nbReview?.toLocaleString() })
        }
      },
      title() {
        if (this.active?.isUserItem === false) {
          return `${this.$t('competitors_widget_average_score_title')} - ${this.active.name}`
        } else {
          return this.$t('competitors_widget_average_score_title')
        }
      },
      comparedScore() {
        if (this.score && this.comparedLmBrandScore) {
          const comparedScore = this.$helpers.numeric.avg(Object.values(this.comparedLmBrandScore), 'avgScore')

          return this.$helpers.numeric.safeDiff(comparedScore, this.score)
        }
      },
      lrmBrandScoreRequest() {
        return this.$basedRequest().select({
          lm_brand_topic_summary_months: [
            { SUM_nb_review: { as: 'nbReview' } },
            { avg_score: { as: 'avgScore' } }
          ]
        }).where({
          lm_brand_id: this.lmBrandIds,
          country_code: this.countryCode,
          stem_group_topic_id: null
        }).group('lm_brand_id')
      }
    },
    asyncComputed: {
      async lmBrandScore() {
          if (!this.dashboardFilterReady) {
          return
        }
        const request = this.lrmBrandScoreRequest.dateBetween(
          this.sqlDatesScope.dateBegin,
          this.sqlDatesScope.dateEnd
        )
        return (await this.$resolve(request)).data
      },
      async comparedLmBrandScore() {
        if (!this.dashboardFilterReady) {
          return
        }
        const request =  this.lrmBrandScoreRequest.dateBetween(
          this.sqlDatesScope.comparedDateBegin,
          this.sqlDatesScope.comparedDateEnd
        )
        return (await this.$resolve(request)).data
      }
    }
  }
</script>

<style lang='stylus'>
</style>
