<template>
  <WStatsWidget
    :cols="12"
    :empty="(!nbPromoters && !nbNeutrals && !nbDetractors) &&
            (!data || (!data.nbPromoters && !data.nbDetractors && !data.nbNeutrals))"
    :loading="!!basedRequest && loading"
    :subtitle="displayedSubtitle"
    :title="title || $t('repartition_widget_title')"
    class="repartition-widget"
    contentPadding="0.75em"
    contentWidth="100%"
    height="200px"
    ref="widget"
  >
    <template #options>
      <w-drop-down-menu
        data-html2canvas-ignore
        v-if="exportOptions && !loading"
        :items="exportOptions"
        icon="mdi-download">
      </w-drop-down-menu>
    </template>

    <template #content>
      <WRepartitionHorizontalFrieze
        :nb-promoters="nbPromoters ?? data.nbPromoters"
        :nb-detractors="nbDetractors ?? data.nbDetractors"
        :nb-neutrals="nbNeutrals ?? data.nbNeutrals"
        :promoters-label="promotersLabel"
        :neutrals-label="neutralsLabel"
        :detractors-label="detractorsLabel"
        :promoters-tooltip="promotersTooltip"
        :neutrals-tooltip="neutralsTooltip"
        :detractors-tooltip="detractorsTooltip"
        :promoters-legend="promotersLegend"
        :neutrals-legend="neutralsLegend"
        :detractors-legend="detractorsLegend"
        unit="%"
        height="62px"
        max-width="100%"
        :emoticons="true"
      />
    </template>

    <template #footer-left>
    </template>

    <template #footer-right>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetMixin from './widget_mixin'

  export default {
    name: "RepartitionWidget",
    data() {
      return {
        loading: false,
      }
    },
    mixins: [
      WidgetMixin
    ],
    props: {
      title: { type: String },
      basedRequest: { type: Object },
      campaign: { type: Object },
      nbPromoters: { type: Number, default: null },
      nbNeutrals: { type: Number, default: null },
      nbDetractors: { type: Number, default: null },
      promotersLabel: { type: String },
      neutralsLabel: { type: String },
      detractorsLabel: { type: String },
      promotersTooltip: { type: String },
      neutralsTooltip: { type: String },
      detractorsTooltip: { type: String },
      promotersLegend: { type: String },
      neutralsLegend: { type: String },
      detractorsLegend: { type: String }
    },
    asyncComputed: {
      data: {
        lazy: true,
        async get() {
          if (!this.basedRequest) {
            return null
          }

          this.loading = true

          let request = this.basedRequest

          if (this.campaign?.id) {
            request = request.where({ campaign_id: this.campaign.id})
          }

          if (this.placeIdsScope && this.placeIdsScope.length) {
            request = request.where({
              place_campaigns_place_id: this.placeIdsScope,
            })
          }

          const data = (await this.$resolve(request)).first()

          this.loading = false

          return data
        }
      }
    },
    computed: {
      ...mapGetters([
        'placeIdsScope'
      ])
    }
  }
</script>
